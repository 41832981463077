import { AnalysisStatus, ProcessingStatus, RowProcessingStatus, ScenarioResultModel } from "../../swagger-clients/excel-runner-api-clients.service";
import { FilterParameterType, InputFilterParameterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";


export const GetScenarioCurrentStep = (analysisStatus?: AnalysisStatus) => {
    if (analysisStatus === undefined)
        return null;

    if (analysisStatus !== AnalysisStatus.AnalyzedSuccessfuly) {
        return "Analysis";
    }
    else return "Calculate jobs";
}
export const GetScenarioCurrentStepStatus = (analysisStatus?: AnalysisStatus, processingStatus?: ProcessingStatus) => {
    if (analysisStatus === undefined || processingStatus === undefined)
        return null;

    if (analysisStatus !== AnalysisStatus.AnalyzedSuccessfuly) {
        return GetAnalysisStatus(analysisStatus);
    }
    else return GetProcessingStatus(processingStatus);
}

export const GetAnalysisStatus = (status: AnalysisStatus): string => {

    switch (status) {
        case AnalysisStatus.AnalysisFailed:
            return "Analysis failed";
        case AnalysisStatus.AnalyzedSuccessfuly:
            return "Analyzed successfully";

        case AnalysisStatus.NotAnalyzed:
            return "Not analyzed";

        default:
            return "";

    }

}


export const GetProcessingStatus = (status: ProcessingStatus) => {

    switch (status) {
        case ProcessingStatus.Finished:
            return "Successfully calculated";
        case ProcessingStatus.NotRunning:
            return "Not calculated";

        case ProcessingStatus.Running:
            return "In progress";

        case ProcessingStatus.Stopped:
            return "Stopped";

        default:
            return "";

    }
}

export const GetCompletedJobsText = (scenario: ScenarioResultModel): string => {

    const { calculatedRows, totalRows, failedRows } = scenario;


    const calculatedPercentage = Number(totalRows) > 0 ?
        Math.round(((Number(calculatedRows) + Number(failedRows)) / Number(totalRows)) * 100) : 100;

    return `${calculatedPercentage}% (${calculatedRows}/${failedRows} of ${totalRows})`;
}

export const GetFilterParameterTypeName = (filterType: FilterParameterType): string | undefined => {

    switch (filterType) {
        case FilterParameterType.FixedValue:
            return "Fixed Value";
        case FilterParameterType.Formula:
            return "Formula";
        default:
            return undefined;
    }
}


export const GetFilterParameterTypeValue = (inputParam: InputFilterParameterResultModel): number | string | undefined => {

    switch (inputParam.parameterType) {

        case FilterParameterType.FixedValue:
            return inputParam.fixedValue;

        case FilterParameterType.Formula:
            return inputParam.formula;

        default:
            return undefined;
    }
}

export const GetRowProcessingStatusText = (rowProcessingStatus: RowProcessingStatus): string => {

    switch (rowProcessingStatus) {
        case RowProcessingStatus.NotSubmittedToDispatcher:
            return "Not submitted to dispatcher";
        case RowProcessingStatus.SubmittedToDispatcher:
            return "Submitted to dispatcher";
        case RowProcessingStatus.Calculated:
            return "Calculated";
        case RowProcessingStatus.CalculationFailed:
            return "Calculation failed";
        case RowProcessingStatus.ProcessingFailed:
            return "Processing failed";
        default:
            return "";
    }
}