import { ErrorOption, UseFormSetError } from "react-hook-form";
import { toast } from "react-toastify";
import { ErrorResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";

const processServerError = (error: any, setError?: UseFormSetError<any>, defaultMessage?: string, showToast: boolean = true) => {
    logErrorInConsole(error, defaultMessage);
    if (error && error.error) {
        if (showToast)
            toast.error(error.error);
        return;
    }

    const errorMessages = getErrorMessagesFromResponse(error, setError, defaultMessage);
    
    if (showToast)
        toast.error(errorMessages[0]);


}

export const getErrorMessagesFromResponse = (error: unknown, setError?: UseFormSetError<any>, defaultMessage?: string,) => {
    const errorResultModel = error as ErrorResponseModel;
    let errorMessages: string[] = [];


    if (errorResultModel.errors && errorResultModel.errors.length > 0) {
        errorMessages.push(errorResultModel.errors[0]);
    } else
        if (errorResultModel.validationErrors) {
            let errorMessages: string[] = [];
            Object.entries(errorResultModel.validationErrors).forEach(([k, v]) => {
                if (setError) {
                    setError(k, { message: v[0] } as ErrorOption);
                } else {
                    errorMessages.push(...v);
                }
            });
            if (!setError) {

                errorMessages.push(errorMessages.reduce((e1, e2) => e1 + "\n" + e2));
            }
        } else {
            if (defaultMessage) {

                errorMessages.push(defaultMessage);
            } else {

                errorMessages.push("An error occurred.");
            }
        }
    return errorMessages;
}

const logErrorInConsole = (error: any, defaultMessage?: string) => {
    if (defaultMessage) {
        console.log(defaultMessage, error);
    } else {
        console.log("An error occurred.", error);
    }
}

export { processServerError }