import { IPublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate, useIsAuthenticated } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Home from './pages/authorization/login-register-overlay';
import Layout from './layouts/layout'
import { CustomNavigationClient } from './utils/CustomNavigationClient';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import { processServerError } from './utils/helpers/error.helper';
import { FeatureFlagsSettings, GeneralSettings, SettingsClient, UrlSettings } from './swagger-clients/s365-dashboard-v2-web-clients.service';
import { HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { getUserToken } from './services/dashboard.service';
import { FluentProvider, Theme, webDarkTheme, webLightTheme } from '@fluentui/react-components';
import { Filters } from './filters/filters.component';
import { EditFilter } from './filters/edit-filter/edit-filter.component';
import { FilterDetails } from './filters/filter-details/filter-details.component';
import { SensitivityStudiesList } from './sensitivity-studies/sensitivity-studies-list.component';
import { EditSensitivityStudy } from './sensitivity-studies/edit-sensitivity-study/edit-sensitivity-study.component';
import { SensitivityStudyDetails } from './sensitivity-studies/sensitivity-study-details/sensitivity-study-details.component';
import { AllUserStudies } from './sensitivity-studies/all-user-studies/all-user-studies.component';
import StarredFilesPage from './pages/StarredFiles/starred-files.page';
import { ApplicationList } from './cluster/applications/applications-list.component';
import { AgentsList } from './cluster/agents/agents-list.component';
import FilesListPage from './pages/FilesList/files-list.page';
import RemovedFilesPage from './pages/RemovedFiles/removed-files.page';
import { Exams } from './take-home-exams/exams.component';
import { EditExam } from './take-home-exams/edit-exam/edit-exam.component';
import { ExamDetails } from './take-home-exams/exam-details/exam-details.component';
import { SudentExams } from './take-home-exams/students/student-exams.component';
import { SubmitResults } from './take-home-exams/students/submit-results/submit-results.component';
import { GroupsTable } from './contacts/groups/groups-table.component';
import { ScenariosTable } from './excel-runner/scenarios-table.component';
import { EditScenario } from './excel-runner/edit-scenario/edit-scenario.component';
import { ScenarioDetails } from './excel-runner/scenario-details/scenario-details.component';
import { FilePickerTestPage } from './pages/FilePickerTestPage/file-picker-test-page';
import { AllUserScenarios } from './excel-runner/all-user-scenarios/all-user-scenarios.component';
import { EulaModal } from './pages/eula/eula-modal/eula-modal.component';
import { Eula } from './pages/eula/eula.component';
import { JobsTable } from './cluster/jobs/jobs-table.component';
import { DispatcherActivities } from './cluster/dispatcher-activities/dispatcher-activities.component';
import { HubConnectionComponent, HubConnectionComponentType } from './components/hub-connection/hub-connection.component';
import { LoginTracker } from './components/login-tracker/login-tracker.component';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ContactList } from './contacts/my-contacts/contacts-list.component';
import SharedByMeFilesPage from './pages/SharedByMe/shared-by-me-files-table';
import SharedWithMeFilesPage from './pages/SharedWithMe/shared-with-me-files-table';
import ExampleFilesPage from './pages/ExampleFiles/example-files.page';
import { FlowsheetExperiments } from './ai-for-pfd/flowsheet-experiments.component';
import { EditExperiment } from './ai-for-pfd/edit-experiment/edit-experiment.component';
import { ExperimentDetails } from './ai-for-pfd/details-experiment/experiment-details.component';
import { AllUserExperiments } from './ai-for-pfd/all-user-experiments/all-user-experiments.component';
import { FilterWebFormPage } from './pages/filter-web-form/filter-web-form.page';
import { FilterWebFormResultsPage } from './pages/filter-web-form/filter-web-form-results.page';
import { ClusterSettingsDetails } from './cluster/settings/cluster-settings-details.component';
import { EditClusterSettings } from './cluster/settings/edit-cluster-settings.component';
import { AllTeacherExams } from './take-home-exams/teacher/all-teacher-exams.component';
import { AllUserWebForms } from './filters/all-user-web-forms/all-user-web-forms.component';
import { EditDoeExperiment } from './excel-runner/edit-experiment/edit-experiment.component';
import { WelcomePage } from './pages/welcome/welcome.page';
import { RoutesDefinitions } from './RoutesDefinitions';


type AppProps = {
  pca: IPublicClientApplication
};

export var settings: UrlSettings | undefined = undefined;
export var generalSettings: GeneralSettings | undefined = undefined;
export var featureFlagsSettings: FeatureFlagsSettings | undefined = undefined;

function App({ pca }: AppProps) {

  const [isLoadingConfig, setIsLoadingConfig] = useState<boolean>(true);
  // Used to show tour modal after user accepts Eula 

  const [currentTheme, setCurrentTheme] = useState<Theme>(webLightTheme);

  const navigate = useNavigate();

  useEffect(() => {
    onComponentInit();
  }, []);

  const onComponentInit = async () => {
    const s365Theme = window.localStorage.getItem("s365-theme");
    if (s365Theme == "dark") {
      setCurrentTheme(webDarkTheme);
    }
    await getConfiguration();
  }

  const onChangeTheme = (theme: Theme, isDark: boolean) => {
    setCurrentTheme(theme);
    window.localStorage.setItem("s365-theme", isDark ? "dark" : "light");
  }

  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  const getConfiguration = async () => {
    try {
      setIsLoadingConfig(true);
      let baseUrl = window.location.origin.indexOf("https://localhost:44485") > -1 ? process.env.REACT_APP_DASHBOARD_WEB_URL : window.location.origin;
      const client = new SettingsClient(baseUrl);
      const settingsResp = await client.getSettings();
      settings = settingsResp?.urlSettings;
      generalSettings = settingsResp?.generalSettings;
      featureFlagsSettings = settingsResp?.featureFlagsSettings;

      console.log("featureFlagsSettings", featureFlagsSettings);
    } catch (error) {
      processServerError(error, undefined, "An error occurred while loading configuration.");
    }
    finally {
      setIsLoadingConfig(false);
    }
  }

  if (!!isLoadingConfig) return <span></span>;

  //console.log("App dispatcher hub", dispatcherServiceHubConnectionRef.current?.hubConnection);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false, // default: true
      },
    },
  })  

  return (
    <QueryClientProvider client={queryClient}>
      <FluentProvider theme={currentTheme}>
        <MsalProvider instance={pca}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          style={{ zIndex: 1000001 }}
          draggable
          pauseOnHover
        />
            <RoutesDefinitions currentTheme={currentTheme} onChangeTheme={onChangeTheme} />
        </MsalProvider>
      </FluentProvider>
    </QueryClientProvider >
  );
}

export default App;
