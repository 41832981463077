import { Configuration, PopupRequest } from "@azure/msal-browser";
import { redirect } from "react-router-dom";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: "e4f0c8f8-5ebd-4d9a-a008-d0fae54ab6a1",
        authority: "https://login.microsoftonline.com/eb2542b8-5a5d-4f61-a9b5-6ce7dbc4ebfd",
        redirectUri: "/",        
        postLogoutRedirectUri: "/"
    },
    cache:{
        cacheLocation: "localStorage",
       
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]   
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};