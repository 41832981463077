import React, { useMemo } from "react";
import { useLoading } from "../../utils/loading-indicator.component";
import { useForm } from "react-hook-form";
import { OrganizationResultModel, ShareDefinitionDestinationType, ShareDefinitionPostModel, ShareDefinitionResponseModel, ShareDefinitionSourceType, SpecialShareType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions, Select, Field, SelectOnChangeData, Table, TableCell, TableHeader, TableHeaderCell, TableRow, Avatar, Tooltip, Input, tokens } from "@fluentui/react-components";
import { Building24Regular, Dismiss24Filled, DismissRegular, InfoFilled, PeopleAudience24Regular, PeopleCommunity24Regular, StopFilled } from "@fluentui/react-icons";
import { ContactPicker } from "./contact-picker/contact-picker.component";
import { processServerError } from "../../utils/helpers/error.helper";
import { getDashboardSharingClient, getOrganizationsClient, getUsersClient } from "../../services/dashboard.service";
import { UserOwnedGroupPicker } from "./user-owned-group-picker/user-owned-group-picker.components";
import { toast } from "react-toastify";
import { TableBodyWithLoading } from "s365-dashboard-v2-file-picker";
import ShowLocalTime from "../../components/show-local-time/show-local-time.component";
import { PersonDisplay } from "../../components/person-display/person-display.component";
import { Alert } from "@fluentui/react-components/unstable";
import { excelExtensions, flowsheetExtensions } from "../../global.variables";
import { getFileExtension } from "../file-type-icon/file-type-icon.helpers";
import { _copyAndSort } from "../../utils/helpers/array.helpers";



type ShareFileModalProps = {
    sourceName?: string;
    sourceUniqueId: string;
    sourceType: ShareDefinitionSourceType;
    isOpened: boolean;
    onSuccess?: (data: ShareDefinitionPostModel) => void;
    onClose: () => void;
}

type ShareWithOption = {
    value: ShareDefinitionDestinationType,
    label: string
}

const defaultShareWithOptions: ShareWithOption[] = [{ value: ShareDefinitionDestinationType.User, label: "Contact" }, { value: ShareDefinitionDestinationType.Group, label: "Group" }];

// Share file or folder
export const ShareFileModal: React.FC<ShareFileModalProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [organization, setOraganization] = React.useState<OrganizationResultModel>();
    const [shareWith, setShareWith] = React.useState<ShareDefinitionDestinationType>(ShareDefinitionDestinationType.User);
    const [shareWithOptions, setShareWithOptions] = React.useState<ShareWithOption[]>(defaultShareWithOptions);
    const [isLoading, loadingService] = useLoading();
    const [isSubmitRequestInProgress, setIsSubmitRequestInProgress] = React.useState<boolean>(false);
    const [sharedDefinitions, setSharedDefinitions] = React.useState<ShareDefinitionResponseModel[]>([]);
    const [showInfo, setShowInfo] = React.useState<boolean>(false);

    const { register, handleSubmit, watch, setError, formState: { errors }, setValue } = useForm<ShareDefinitionPostModel>({
        defaultValues: {
            destinationType: ShareDefinitionDestinationType.User,
            sourceId: props.sourceUniqueId,
            sourceType: props.sourceType
        },
        reValidateMode: "onChange"
    });

    const shareDestinationType = watch("destinationType");


    React.useEffect(() => {
        onInit();
    }, []);
    const onInit = async () => {
        await getUserOrganization();
        checkShowInfo();
        getSharingDefinitions();
        await checkIfUserIsExamplesAdmin();

    }
    const sourceTypeName = useMemo(() => { return getSourceTypeName(props.sourceType); }, [props.sourceType]);

    const checkShowInfo = () => {
        if (props.sourceType == ShareDefinitionSourceType.File) {
            const fileExtension = getFileExtension(props.sourceName).toLowerCase();
            const canHaveAttachments = [...flowsheetExtensions, ...excelExtensions].indexOf(fileExtension) > -1;
            setShowInfo(canHaveAttachments);
        }
    }

    const checkIfUserIsExamplesAdmin = async () => {
        try {
            const client = getUsersClient();
            const resp = await client.isUserExamplesAdmin();


            if (!!resp && resp.isUserExamplesAdmin) {
                setShareWithOptions(s => {
                    const updatedShareOptions = [...s, { label: "Public Examples", value: ShareDefinitionDestinationType.Public }];
                    const sortedShareOptions = _copyAndSort<ShareWithOption>(updatedShareOptions, "value", false);

                    return sortedShareOptions;
                });
            }

        } catch (error) {
            processServerError(error, undefined, "An error occurred while check if user is examples admin.");
        }
    }

    const getUserOrganization = () => {
        loadingService.showLoading("Getting user organization...", async (hideMessage) => {
            try {
                const client = getOrganizationsClient();
                const myOrg = await client.getMyOrganization();
                setOraganization(myOrg);
                if (!!myOrg) {
                    setShareWithOptions(s => {
                        const updatedShareOptions = [...s, { value: ShareDefinitionDestinationType.Organization, label: "Organization" }];
                        const sortedShareOptions = _copyAndSort<ShareWithOption>(updatedShareOptions, "value", false);

                        return sortedShareOptions;
                    });
                }

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting user organization;");
            } finally {
                hideMessage();
            }
        });
    }

    const getSharingDefinitions = async () => {
        loadingService.showLoading("Loading sharings...", async (hideMessage) => {
            try {
                const client = getDashboardSharingClient();
                const sharingDefinitionsResp = await client.getSharedDefinitions(props.sourceUniqueId, props.sourceType);
                setSharedDefinitions(sharingDefinitionsResp ?? []);
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting sharings.");
            } finally {
                hideMessage();
            }
        });

    }

    React.useEffect(() => { setOpen(!!props.isOpened); }, [props.isOpened]);


    const onModalClose = () => {
        setOpen(false);
        props.onClose();
    }

    const onDestinationSelected = (destinationId?: string) => {
        setValue("destinationId", destinationId);
    }

    const onShareWithChange = (ev, data: SelectOnChangeData) => {
        const shouldValidate = !!errors.destinationId;
        setValue("destinationId", "", { shouldDirty: true, shouldTouch: true, shouldValidate: shouldValidate });
        setValue("destinationType", +data.value, { shouldDirty: true, shouldTouch: true, shouldValidate: shouldValidate });
        setValue("destinationId", undefined, { shouldDirty: true, shouldTouch: true, shouldValidate: shouldValidate });
        //  setError("destinationId", undefined);
        setShareWith(+data.value);

        if (+data.value == +ShareDefinitionDestinationType.Organization) {
            setValue("destinationId", organization.id.toString());
        }
    }

    const onSubmit = async (data: ShareDefinitionPostModel) => {

        console.log("Sharing with", data);

        try {
            setIsSubmitRequestInProgress(true);
            const client = getDashboardSharingClient();
            if (data.destinationType == ShareDefinitionDestinationType.Public) {
                data.specialShareType = SpecialShareType.Examples;
            }
            await client.shareWith(data);
            const sourceTypeNameToPascal = sourceTypeName.charAt(0).toUpperCase() + sourceTypeName.slice(1);
            if (!props.onSuccess) {
                toast.success(` ${sourceTypeNameToPascal} shared successfully.`);
            } else {
                props.onSuccess?.(data);
            }
            getSharingDefinitions();
        } catch (error) {
            processServerError(error, undefined, `An error occurred while sharing ${sourceTypeName}/s.`);
        } finally {
            setIsSubmitRequestInProgress(false);
        }
    }

    const onStopSharingClick = async (item: ShareDefinitionResponseModel) => {

        try {
            setIsSubmitRequestInProgress(true);
            const client = getDashboardSharingClient();
            await client.stopSharing(props.sourceUniqueId!, item.id!);
            toast.success(`Sucessfully stopped sharing ${sourceTypeName}/s.`);
            getSharingDefinitions();
        } catch (error) {
            processServerError(error, undefined, `An error occurred while stoping sharing ${sourceTypeName}/s.`);
        } finally {
            setIsSubmitRequestInProgress(false);
        }
    }

    console.log("shareDestinationType", shareDestinationType);

    return <Dialog open={open} onOpenChange={(event, data) => {

        setOpen(data.open);
        if (!data.open) {
            onModalClose();
        }


    }}>
        <DialogSurface style={{ width: "70vw", maxWidth: "700px" }}>

            <DialogBody style={{ maxWidth: "100%" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                    Share {getSourceTypeName(props.sourceType)}: <i>{props.sourceName}</i> </DialogTitle>
                <DialogContent style={{ height: "80vh" }}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
                            <Field
                                label="Share with"
                                style={{ width: "150px" }}
                            >  <Select onChange={onShareWithChange}>
                                    {shareWithOptions.map((item) => {
                                        return <option value={item.value}>{item.label}</option>
                                    })}


                                </Select>
                            </Field>
                            {shareDestinationType === ShareDefinitionDestinationType.User &&
                                <Field style={{ marginTop: "24px" }} validationState={errors.destinationId ? "error" : undefined} validationMessage={!!errors.destinationId ? "User is required." : undefined}>
                                    <ContactPicker
                                        placeholder="Find contact"
                                        style={{ width: "300px" }}
                                        {...register("destinationId", { required: { value: true, message: "User is required." } })}
                                        onChange={(contact) => {
                                            onDestinationSelected(contact?.value.toString());
                                        }} />
                                </Field>
                            }
                            {shareDestinationType === ShareDefinitionDestinationType.Group &&
                                <Field style={{ marginTop: "24px" }} validationState={errors.destinationId ? "error" : undefined} validationMessage={!!errors.destinationId ? "Group is required." : undefined}>
                                    <UserOwnedGroupPicker placeholder="Find group"
                                        style={{ width: "300px" }}
                                        {...register("destinationId", { required: { value: true, message: "Group is required." } })}
                                        onChange={(group) => {
                                            onDestinationSelected(group?.value.toString());
                                        }} />
                                </Field>
                            }
                            {shareDestinationType === ShareDefinitionDestinationType.Organization &&
                                <span style={{ lineHeight: "32px", marginTop: "24px", width: "300px", borderBottom: "1px solid var(--colorNeutralStrokeAccessible)", textAlign: "center" }}
                                >Share with <b>{organization.name}</b></span>}

                            {shareDestinationType === ShareDefinitionDestinationType.Public &&
                                <Field style={{ marginTop: "24px" }}>
                                    <Input
                                        {...register("destinationId", { required: false })}
                                        style={{ display: "none" }}
                                    />
                                </Field>
                            }

                            <Button appearance="primary" type="submit" disabled={isSubmitRequestInProgress} style={{ marginTop: "24px" }} >Share</Button>
                        </div>
                    </form>
                    {showInfo && (props.sourceType == ShareDefinitionSourceType.File || ShareDefinitionSourceType.Directory) &&
                        <Alert intent="info" className="alert--info-global" style={{ marginTop: "var(--spacingVerticalXL)" }}>
                            Only the {sourceTypeName} will be shared. Other attached data like filters, exam, studies etc. won't be shared.
                        </Alert>}
                    {props.sourceType == ShareDefinitionSourceType.Filter && <Alert intent="info" className="alert--info-global" style={{ marginTop: "var(--spacingVerticalXL)" }}>
                        By sharing the Filter, you also share the corresponding flowsheet version.
                    </Alert>}
                    <div style={{ maxHeight: "calc(80vh - 80px)", overflowY: "auto", marginTop: "var(--spacingVerticalXL)" }}>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHeaderCell key="displayName" className='groups-table__cell--bold'>Display name</TableHeaderCell>
                                    <TableHeaderCell key="sharedAt" style={{ width: "200px" }} className='groups-table__cell--bold  column--center'>Shared</TableHeaderCell>
                                    <TableHeaderCell key="actions" style={{ width: "50px" }} className='groups-table__cell--bold  column--center'></TableHeaderCell>
                                </TableRow>
                            </TableHeader>
                            <TableBodyWithLoading isLoading={isLoading}
                                columnCount={2} loadingMessage="Loading..."
                                itemCount={sharedDefinitions ? sharedDefinitions.length : 0}
                                noItemsMessage="No results.">
                                {sharedDefinitions && sharedDefinitions.length > 0 && sharedDefinitions.map((item, index) => {


                                    return <TableRow>

                                        <TableCell>
                                            {item.destinationType == ShareDefinitionDestinationType.User &&
                                                <PersonDisplay id={item.user?.id} name={item.user?.displayName} userPrincipalName={item.user?.userPrincipalName} />}
                                            {item.destinationType == ShareDefinitionDestinationType.Group &&
                                                <PersonDisplay id={`shared-with-${index}`}
                                                    icon={<PeopleCommunity24Regular />}
                                                    name={item.group?.displayName}
                                                    userPrincipalName={"Group"} />
                                            }
                                            {item.destinationType == ShareDefinitionDestinationType.Organization &&
                                                <PersonDisplay id={`shared-with-${index}`}
                                                    icon={<Building24Regular />}
                                                    name={item.oraganization?.name}
                                                    userPrincipalName={"Organization"} />
                                            }
                                            {item.destinationType == ShareDefinitionDestinationType.Public &&
                                                <PersonDisplay id={`shared-with-${index}`}
                                                    icon={<PeopleAudience24Regular />}
                                                    name="Public Example"
                                                    userPrincipalName="" />
                                            }


                                        </TableCell>
                                        <TableCell className="column--center">
                                            <ShowLocalTime date={item.createdAtUtc} multiline />
                                        </TableCell>
                                        <TableCell>
                                            <div style={{ display: "flex", alignItems: "end", flexDirection: "column", maxWidth: "50px" }}>
                                                <Tooltip content="Stop sharing" relationship="label">
                                                    <Button
                                                        appearance="transparent"
                                                        disabled={isSubmitRequestInProgress}
                                                        onClick={() => { onStopSharingClick(item) }}
                                                        icon={<DismissRegular primaryFill="red" />}></Button>
                                                </Tooltip>
                                            </div>

                                        </TableCell>

                                    </TableRow>
                                })}
                            </TableBodyWithLoading>
                        </Table>
                    </div>

                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}

const getSourceTypeName = (sourceType: ShareDefinitionSourceType) => {
    switch (sourceType) {
        case ShareDefinitionSourceType.File:
            return "file";
        case ShareDefinitionSourceType.Directory:
            return "folder";
        case ShareDefinitionSourceType.CopilotExperiment:
            return "experiment";
        case ShareDefinitionSourceType.DoEExperiment:
            return "experiment";
        case ShareDefinitionSourceType.Filter:
            return "filter";
        default:
            return "";
    }
}