import { Tooltip } from "@fluentui/react-components";
import { ChartPersonRegular, DataScatterFilled, DataScatterRegular, DataTrendingFilled, DataTrendingRegular, DocumentDataRegular, DocumentTextClockRegular, PulseRegular, TaskListSquareAddRegular } from "@fluentui/react-icons";
import { Spinner } from "@fluentui/react-spinner";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFileExtension } from "../../files/file-type-icon/file-type-icon.helpers";
import { excelExtensions, flowsheetExtensions } from "../../global.variables";
import { getFiltersCountClient, getUserToken } from "../../services/dashboard.service";
import { getScenarioCountClient } from "../../services/excel-runner.service";
import { getStudiesCountClient } from "../../services/sensitivity-studies.service";
import { getExamCountClient } from "../../services/take-home-exams.service";
import { ScenarioCountPostModel } from "../../swagger-clients/excel-runner-api-clients.service";
import { FiltersCountPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { ExamCountPostModel } from "../../swagger-clients/s365-take-home-exams-v2-clients.service";
import { StudiesCountPostModel } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { getExperimentsCountClient } from "../../services/ai-for-pfd.service";
import { ExperimentCountPostModel } from "../../swagger-clients/ai-for-pfd-clients.service";

type CountsIconInternalProps = {
    fileUniqueId: string,
    url: string,
    tooltip?: string,
    provider: CountsSingletonProviderBase,
    icon: JSX.Element
};

export const CountsIcon: React.FC<any> = (props: CountsIconInternalProps) => {

    const [count, setCount] = useState<number>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.fileUniqueId && props.provider) {
            // console.log(`Load count with provider ${props.provider?.constructor.name} for file ${props.fileUniqueId}.`);

            setIsLoading(true);
            props.provider.GetCount(props.fileUniqueId)
                .then(cnt => setCount(cnt))
                .catch(() => { /* This is required to hide Network error overlay when server is unavailable */ })
                .finally(() => setIsLoading(false));
        }
    }, [props.fileUniqueId, props.provider]);

    if (count == 0) {
        return null;
    }

    return <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '30px' }}>
        {isLoading ? <Spinner size="tiny" /> :
            <Tooltip relationship="description" positioning="above" content={props.tooltip}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: "pointer", color: (count === undefined ? "var(--colorPaletteRedForeground1)" : undefined) }}
                    onClick={() => navigate(props.url)}>
                    {props.icon}
                    <small>({count !== undefined ? count : "-"})</small>
                </div>
            </Tooltip>
        }
    </div>;
}







 abstract class CountsSingletonProviderBase {

    protected files: string[] = [];
    private promisses = {};
    private timeoutHandler = null;

    abstract LoadCountsInternal(files: string[]);
    abstract GetResponseFileIdPropertyName();

    GetCount(fileUniqueId: string): Promise<number> {
        this.files.push(fileUniqueId);

        let promise = new Promise<number>((resolve, reject) => {
            this.promisses[fileUniqueId.toLowerCase()] = [resolve, reject];
        });

        if (!this.timeoutHandler) {
            // console.log(`Creating timeout for loading ${this.constructor.name} counts.`);
            this.timeoutHandler = setTimeout(() => {
                this.LoadCounts();
                this.timeoutHandler = null;
            }, 500);
        }

        return promise;
    }

    private LoadCounts() {
        //  console.log(`Starting loading counts for ${this.constructor.name}.`, this.files);

        this.LoadCountsInternal([...this.files])
            .then(response => {
                for (let o of response) {
                    let fileId = o[this.GetResponseFileIdPropertyName()];
                    this.promisses[fileId.toLowerCase()][0](o.count);
                    delete this.promisses[fileId.toLowerCase()];
                }

                // Trigger promise where count is zero
                for (let k of Object.keys(this.promisses)) {
                    this.promisses[k][0](0);
                    delete this.promisses[k];
                }
            }).catch((error) => {
                // If request fails reject all promisese
                for (let k of Object.keys(this.promisses)) {
                    this.promisses[k][1](error);
                    delete this.promisses[k];
                }
            });


        this.files = [];
    }
}

type CountsIconProps = {
    fileUniqueId: string,
    filename: string,
    url: string,
    tooltip?: string
};

export const FiltersCountIcon: React.FC<any> = (props: CountsIconProps) => {
    const fileExtension = getFileExtension(props.filename);

    if (flowsheetExtensions.indexOf(fileExtension) == -1)
        return null;

    return <CountsIcon provider={filtersCountProvider}
        icon={<TaskListSquareAddRegular style={{ fontSize: '1.2rem' }} />}
        fileUniqueId={props.fileUniqueId}
        url={props.url}
        tooltip={props.tooltip} />;
}


export const ScenariosCountIcon: React.FC<any> = (props: CountsIconProps) => {
    const fileExtension = getFileExtension(props.filename);

     if (excelExtensions.indexOf(fileExtension) == -1 && flowsheetExtensions.indexOf(fileExtension) == -1)
         return null;

    return <CountsIcon
        provider={scenariosCountProvider}
        icon={<DocumentDataRegular style={{ fontSize: '1.2rem' }} />}
        fileUniqueId={props.fileUniqueId}
        url={props.url}
        tooltip={props.tooltip} />;
}

export const SensitivityStudiesCountIcon: React.FC<any> = (props: CountsIconProps) => {
    const fileExtension = getFileExtension(props.filename);

    if (flowsheetExtensions.indexOf(fileExtension) == -1)
        return null;

    return <CountsIcon
        provider={studiesCountProvider}
        icon={<DataScatterRegular style={{ fontSize: '1.2rem' }} />}
        fileUniqueId={props.fileUniqueId}
        url={props.url}
        tooltip={props.tooltip} />;
}


export const OptimumTestsCountIcon: React.FC<any> = (props: CountsIconProps) => {
    const fileExtension = getFileExtension(props.filename);

    if (flowsheetExtensions.indexOf(fileExtension) == -1)
        return null;

    return <CountsIcon
        provider={optimumTestsCountProvider}
        icon={<DataTrendingRegular style={{ fontSize: '1.2rem' }} />}
        fileUniqueId={props.fileUniqueId}
        url={props.url}
        tooltip={props.tooltip} />;
}

export const ExamsCountIcon: React.FC<any> = (props: CountsIconProps) => {
    const fileExtension = getFileExtension(props.filename);

    if (excelExtensions.indexOf(fileExtension) == -1)
        return null;

    return <CountsIcon
        provider={examsCountProvider}
        icon={<ChartPersonRegular style={{ fontSize: '1.2rem' }} />}
        fileUniqueId={props.fileUniqueId}
        url={props.url}
        tooltip={props.tooltip} />;
}

export const ExperimentCountIcon: React.FC<any> = (props: CountsIconProps) => {
    const fileExtension = getFileExtension(props.filename);

    if (flowsheetExtensions.indexOf(fileExtension) == -1)
        return null;

    return <CountsIcon
        provider={experimentsCountProvider}
        icon={<PulseRegular style={{ fontSize: '1.2rem' }} />}
        fileUniqueId={props.fileUniqueId}
        url={props.url}
        tooltip={props.tooltip} />;
}


class FiltersCountSingletonProvider extends CountsSingletonProviderBase {
    GetResponseFileIdPropertyName() {
        return "fileUniqueIdentifier";
    }
    LoadCountsInternal(files: string[]) {
        let client = getFiltersCountClient();
        return client.getFiltersCount(new FiltersCountPostModel({ fileUniqueIdentifiers: files }))
    }

}

class ScenariosCountSingletonProvider extends CountsSingletonProviderBase {
    GetResponseFileIdPropertyName() {
        return "driveItemId";
    }
    async LoadCountsInternal(files: string[]) {
        const token = await getUserToken();
        let client = getScenarioCountClient();
        return client.getExcelScenarioCount(new ScenarioCountPostModel({ driveItemIds: files, accessToken: token }))
    }
}

class SensitivityStudiesCountSingletonProvider extends CountsSingletonProviderBase {
    GetResponseFileIdPropertyName() {
        return "driveItemId";
    }
    async LoadCountsInternal(files: string[]) {
        let client = getStudiesCountClient();
        return client.getStudiesCountDashboardV2(new StudiesCountPostModel({ driveItemIds: files }))
    }
}

class OptimumTestsCountSingletonProvider extends CountsSingletonProviderBase {
    GetResponseFileIdPropertyName() {
        return "driveItemId";
    }
    async LoadCountsInternal(files: string[]) {
        let client = getStudiesCountClient();
        return client.getOptimumTestsCountDashboardV2(new StudiesCountPostModel({ driveItemIds: files }))
    }
}

class ExamsCountSingletonProvider extends CountsSingletonProviderBase {
    GetResponseFileIdPropertyName() {
        return "driveItemId";
    }
    async LoadCountsInternal(files: string[]) {
        let client = getExamCountClient();
        return client.getExamCount(new ExamCountPostModel({ driveItemIds: files }))
    }
}

class ExperimentsCountSingletonProvider extends CountsSingletonProviderBase {
    GetResponseFileIdPropertyName() {
        return "fileUniqueId";
    }
    async LoadCountsInternal(files: string[]) {
        let client = getExperimentsCountClient();
        return client.getExperimentCount(new ExperimentCountPostModel({ fileUniqueIds: files }))
    }
}

let filtersCountProvider = new FiltersCountSingletonProvider();
let scenariosCountProvider = new ScenariosCountSingletonProvider();
let studiesCountProvider = new SensitivityStudiesCountSingletonProvider();
let optimumTestsCountProvider = new OptimumTestsCountSingletonProvider();
let examsCountProvider = new ExamsCountSingletonProvider();
let experimentsCountProvider = new ExperimentsCountSingletonProvider();