import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, tokens, DialogContent, DialogActions, Input, Select, Field, DialogTrigger, useModalAttributes } from "@fluentui/react-components";
import { Dismiss24Filled, MailRegular } from "@fluentui/react-icons";
import React, { HtmlHTMLAttributes, useRef } from "react";
import ReactDOM from "react-dom";
import { LoadingButton } from "../loading-button/loading-button.component";
import { useLoading } from "../../utils/loading-indicator.component";
import { useForm } from "react-hook-form";
import { FilePicker } from "s365-dashboard-v2-file-picker";
import { settings } from "../../App";
import { getFilesClient, getUserToken } from "../../services/dashboard.service";
import { processServerError } from "../../utils/helpers/error.helper";
import "./save-file-modal.styless.scss";
import { FileModel } from "s365-dashboard-v2-file-picker/build/src/swagger-clients/s365-dashboard-v2-api-clients.service";
import CreateDirectoryModal from "../../files/create-directory-modal/create-directory-modal.component";
import { FilePickerType } from "s365-dashboard-v2-file-picker/build/src/file-picker.component";
import { UploadConflictAction } from "../../swagger-clients/sensitivity-studies-api-clients.service";
import { ValidateFileNamesPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { ConflictModal } from "../conflict-modal/conflict-modal.component";
import { getFileNameWithoutExtension } from "../../files/file-type-icon/file-type-icon.helpers";

type SaveFileModalProps = {
    isOpened?: boolean;
    modalTitle: string;
    saveButtonLabel?: string;
    defaultName?: string;
    fileExtension: string;
    fileExtensionText: string;
    parentDirectoryUniqueId?: string;
    onSuccess: (data: SaveFileModel) => void;
    onHide: () => void;
}

export type SaveFileModel = {
    name: string;
    extension: string;
    parentDirectoryUniqueId?: string;
    conflictAction?: UploadConflictAction;
};

export const SaveFileModal: React.FC<SaveFileModalProps> = (props) => {
    const [isLoading, loadingService] = useLoading();
    const { register, handleSubmit, watch, setError, formState: { errors }, setValue } = useForm<SaveFileModel>({
        defaultValues: {
            extension: props.fileExtension,
            parentDirectoryUniqueId: props.parentDirectoryUniqueId,
            name: !!props.defaultName ? props.defaultName : ""
        }
    });
    const [accessToken, setAccessToken] = React.useState<string>();
    const [showCreateDirectoryDialog, setShowCreateDirectoryDialog] = React.useState<boolean>(false);
    const [showConflictModal, setShowConflictModal] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState(false);
    const filePickerRef = useRef<FilePickerType>();

    const conflictModalButtonRef = useRef<HTMLButtonElement>();

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    const name = watch("name");
    React.useEffect(() => {

        if (!name && props.defaultName) {
            console.log("setting default name", props.defaultName);
            setValue("name", props.defaultName, { shouldDirty: true, shouldTouch: true });
        }
    }, [props.defaultName]);

    const onModalClose = () => {
        setOpen(false);
        if (props.onHide)
            props.onHide();
    }
    const parentDirectoryUniqueId = watch("parentDirectoryUniqueId");

    React.useEffect(() => { getAccessToken(); }, []);
    const getAccessToken = async () => {

        try {
            const token = await getUserToken();
            setAccessToken(token);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting user token.");
        }
    }

    const onSubmit = async (data: SaveFileModel) => {

        console.log("onSubmit", data);
        //check if file exists
        const client = getFilesClient();
        const filename = `${data.name}.${props.fileExtension}`;
        if (data.conflictAction === undefined) {
            const resp = await client.validateFileNames({ fileNames: [filename], parentDirectoryUniqueId: parentDirectoryUniqueId } as ValidateFileNamesPostModel);
            if (resp.existingFiles?.length > 0) {
                setShowConflictModal(true);
                // conflictModalButtonRef?.current?.click();
                // const conflictModalButton = document.getElementById("conflictModalButton");
                // console.log("conflictModalButton", conflictModalButton);
                // const clickEvent = new MouseEvent('click', {
                //     bubbles: true, // Allow the event to propagate
                //     cancelable: true,
                // });
                // conflictModalButton.dispatchEvent(clickEvent);
                return;
            }
        }

        props.onSuccess(data);

    }

    return <div>
        <Dialog
            key={`save-file-modal`}
            open={open}>
            <DialogSurface id="save-file-modal" style={{ maxWidth: "1400px", width: "fit-content", height: "fit-content" }}>
                <form onSubmit={handleSubmit(m => onSubmit(m))}>
                    <DialogBody style={{ height: "90vh", width: "90vw", maxWidth: "1200px" }}>
                        <DialogTitle style={{ display: "flex", alignItems: "center" }} action={<Button appearance="transparent" onClick={() => { onModalClose(); }} icon={<Dismiss24Filled />} />}>
                            {props.modalTitle}
                        </DialogTitle>
                        <DialogContent className="save-file-modal">
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: "hidden" }} >
                                <div style={{ display: "flex", marginBottom: "5px", marginTop: "5px" }}>
                                    <div style={{ flexBasis: "100%" }} >
                                        <Field

                                            validationMessage={errors.name ? errors.name.message : ""}
                                            validationState={errors.name ? "error" : "none"}>
                                            <Input className="input-field--streched"
                                                placeholder="Enter file name here"
                                                {...register("name", {
                                                    required: { value: true, message: "File name is required." },
                                                    pattern: { value: /^[\w\-._ ]+$/, message: `The file name can only contain letters, digits, space or following characters: . - _` }
                                                })}
                                                value={name}
                                                onChange={(ev, data) => { setValue("name", data.value, { shouldDirty: true, shouldTouch: true }); }}
                                                contentAfter={<span>.{props.fileExtension}</span>} />
                                        </Field>
                                    </div>
                                    <div >
                                        <DialogActions>
                                            <LoadingButton isLoading={isLoading} style={{ marginLeft: "var(--spacingHorizontalMNudge)" }} type="submit" appearance="primary">
                                                {!!props.saveButtonLabel ? props.saveButtonLabel : "Export"}</LoadingButton>
                                        </DialogActions>
                                    </div>

                                </div>
                                <div style={{ display: "flex", marginBottom: "5px", marginTop: "5px" }} >
                                    <div style={{ flexBasis: "100%" }}>
                                        <Select
                                            value={props.fileExtension}
                                        >

                                            return <option value={props.fileExtension}>{props.fileExtensionText}</option>

                                        </Select>
                                    </div>
                                    <div>
                                        <DialogTrigger disableButtonEnhancement>
                                            <Button type="button" style={{ marginLeft: "10px", minWidth: "100px" }} onClick={() => setShowCreateDirectoryDialog(true)} >New Folder</Button>
                                        </DialogTrigger>
                                        {/* <DialogTrigger disableButtonEnhancement>
                                            <Button id="conflictModalButton" type="button" ref={conflictModalButtonRef} style={{ width: 0, minWidth: 0, height: 0, padding: 0, border: 0 }}
                                                onClick={() => { setShowConflictModal(true); }} >ConflictModal</Button>
                                        </DialogTrigger> */}

                                    </div>
                                </div>
                                <div className="file-picker-wrapper">
                                    <FilePicker
                                        ref={filePickerRef}
                                        userAccessToken={accessToken}
                                        dashboardServiceUrl={settings.dashboardServiceUrl}
                                        excelRunnerServiceUrl={settings.excelRunnerServiceUrl}
                                        sensitivityStudiesServiceUrl={settings.sensitivityStudiesServiceUrl}
                                        takeHomeExamServiceUrl={settings.takeHomeExamsServiceUrl}
                                        filterFileTypes={[props.fileExtension]}
                                        onSelectedFileChanged={(file: FileModel) => {
                                            const fileName = getFileNameWithoutExtension(file.name);
                                            setValue("name", fileName, { shouldDirty: true, shouldTouch: true });
                                        }}
                                        onSelectedFolderChanged={(folder: FileModel) => { setValue("parentDirectoryUniqueId", folder?.uniqueIdentifier); }}
                                        baseFolder={props.parentDirectoryUniqueId}
                                    />
                                </div>

                            </div>

                        </DialogContent>

                    </DialogBody>

                </form>

            </DialogSurface>



        </Dialog>

        {showConflictModal && <ConflictModal
            isOpened={showConflictModal}
            modalTitle="Save options"
            buttonLabel="Save"
            onSubmit={(conflictAction) => { setValue("conflictAction", conflictAction); handleSubmit(onSubmit)(); }}
            onClose={() => { setValue("conflictAction", undefined); setShowConflictModal(false); removeTabsterFromModal(); }}
        />}

        {showCreateDirectoryDialog && <CreateDirectoryModal
            parentDirectoryUniqueId={parentDirectoryUniqueId}
            onSuccess={() => { setShowCreateDirectoryDialog(false); filePickerRef.current?.getFilesAndFolders(); removeTabsterFromModal(); }}
            isOpened={true}
            onClose={() => { setShowCreateDirectoryDialog(false); removeTabsterFromModal(); }}
        />}
    </div>


}
// when conflict modal opens and user closes it, input field for name can't be focused
const removeTabsterFromModal = () => {
    const dialogSurface = document.getElementById("save-file-modal");
    if (dialogSurface) {
        dialogSurface.setAttribute("data-tabster", "");
    }
}