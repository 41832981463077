import React, { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, createTableColumn, Input, Toolbar } from '@fluentui/react-components';
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from '../../components/breadcrumbs/breadcrumbs';
import { getFilesClient, getTagsClient } from '../../services/dashboard.service';
import { BreadcrumbItem, FileModel, FileSortFields } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { processServerError } from '../../utils/helpers/error.helper';
import { HubConnection } from '@microsoft/signalr';
import { LoadingIndicator, useLoading } from '../../utils/loading-indicator.component';
import { TagOption } from '../../files/tag-picker/tag-picker.component';
import { FilesTable, FilesTableSort } from '../../files/files-table/files-table.component';
import { FileTableColumn, FileTableItem } from '../../files/models/file-table.models';
import { SidePanel } from '../../files/side-panel/side-panel';
import { FilesDropZone } from './FilesDropZone';
import { NewButton } from './NewButton';
import './files-list.scss';
import EmptyFolderIcon from '../../assets/empty-folder-icon.png';
import { FilesUploadService } from './FilesUploadService';
import { SearchRegular } from '@fluentui/react-icons';
import { ParentPathLink } from '../../files/parent-path-link/parent-path-link.component';


const emptyBreadcrumbs = [{ name: "My Work" } as BreadcrumbItem];

type FilesProps = {
    hubConnection?: HubConnection
}

type FilesListRouteParams = {
    parentDirectoryUniqueId: string
};

const FilesListPage = (props: FilesProps) => {

    const [isLoading, loadingService] = useLoading();
    const filesUploadService = useMemo(() => new FilesUploadService(loadingService), []);

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [files, setFiles] = React.useState<FileTableItem[]>([]);

    const [showTagInputs, setShowTagInputs] = useState<{ [key: string]: boolean }>({});
    const [selectedItem, setSelectedItem] = React.useState<FileTableItem>();
    const [showDetails, setShowDetails] = useState<boolean>(false);

    const [isFilesLoading, setIsFilesLoading] = useState(false);
    const [fileTagDefaultOptions, setFileTagDefaultOptions] = React.useState<TagOption[]>([]);
    const [commentValidationState, setCommentValidationState] = React.useState<'error' | 'warning' | 'success'>();
    const [commentValidationMessage, setCommentValidationMessage] = React.useState<string>();
    const [sortBy, setSortBy] = React.useState<FilesTableSort>({ field: FileSortFields.Name, isDescending: false });

    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const [isSearching, setIsSearching] = React.useState<boolean>(false);

    const scrollableElementRef = React.useRef<any>();

    const routeParams = useParams<FilesListRouteParams>();
    const navigate = useNavigate();

    React.useEffect(() => {
        getTagDefaultSuggestions();
    }, []);



    React.useEffect(() => {
        if (commentValidationMessage) {
            setCommentValidationMessage(undefined);
        }
        if (commentValidationState) {
            setCommentValidationState(undefined);
        }
    }, [selectedItem]);

    React.useEffect(() => {
        getFilesWithLoading(routeParams.parentDirectoryUniqueId, searchTerm);
    }, [routeParams.parentDirectoryUniqueId, sortBy]);


    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {

        setSearchTerm("");
        if (routeParams.parentDirectoryUniqueId == parentDirectoryId) {
            getFilesWithLoading(parentDirectoryId, "");
        } else {
            navigate(`/files/${parentDirectoryId ?? ''}`);
        }
    }

    const getFilesWithLoading = async (parentDirectoryId?: string, term?: string) => {

        const loadingText = !!term ? "Searching..." : "Loading...";
        loadingService.showLoading(loadingText, (hideMessage) => {
            if (!!term) {
                setIsSearching(true);
                searchFiles(parentDirectoryId).finally(() => hideMessage());
            } else {
                setIsSearching(false);
                getFiles(parentDirectoryId).finally(() => hideMessage());
            }
        })
    }
    const searchFiles = async (parentDirectoryId: string) => {
        try {
            try {
                setIsFilesLoading(true); // This separate isLoading is needed to show empty directory icon

                const client = getFilesClient();
                const resp = await client.searchFiles(searchTerm, parentDirectoryId ?? "", sortBy.field, sortBy.isDescending);

                if (resp) {
                    setFiles(resp ?? []);

                }
            }
            catch (error) {
                processServerError(error, undefined, "An error occurred while getting files.");
            }
            finally {
                setIsFilesLoading(false);
            }
        } catch (error) {

        }
    }

    const getFiles = async (parentDirectoryId: string) => {
        try {
            setIsFilesLoading(true); // This separate isLoading is needed to show empty directory icon

            const client = getFilesClient();
            const resp = await client.getFiles(parentDirectoryId ?? "", sortBy.field, sortBy.isDescending);

            if (resp) {
                setFiles(resp.files ?? []);
                if (selectedItem) {
                    const updatedSelectedItem = resp.files?.find(x => x.id == selectedItem.id && x.type == selectedItem.type);
                    // console.log("updatedSelectedItem", updatedSelectedItem);
                    setSelectedItem({ ...updatedSelectedItem } as FileModel);
                }
                setBreadcrumbs(resp.breadcrumbItems ? [...emptyBreadcrumbs, ...resp.breadcrumbItems] : emptyBreadcrumbs);
            }

        }
        catch (error) {
            processServerError(error, undefined, "An error occurred while getting files.");
        }
        finally {
            setIsFilesLoading(false);
        }
    }

    const getTagDefaultSuggestions = async () => {

        try {

            const client = getTagsClient();

            const fileTagSuggestionResp = await client.getFileTagSuggestions("", 0);

            const filetagOptions = fileTagSuggestionResp.map(x => ({ label: x.name, value: x.id } as TagOption));

            setFileTagDefaultOptions(filetagOptions);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting tag name suggestions.");
        }
    }

    const filePathColumn = {
        column: createTableColumn<FileTableItem>({
            columnId: "location",
            renderHeaderCell: () => <>Location</>,
        }),
        cellValue(fileItem) {
            return <ParentPathLink directoryPaths={fileItem.directoryPaths} />
        },

    } as FileTableColumn;

    return (
        <>
            <FilesDropZone
                className='page-wrapper'
                //  disabled={!!searchTerm}
                loadingService={loadingService}
                parentDirectoryUniqueId={routeParams.parentDirectoryUniqueId}
                filesUploadService={filesUploadService}
                onFilesUploaded={() => getFilesWithLoading(routeParams.parentDirectoryUniqueId, searchTerm)}>

                <div className='files-wrapper' ref={scrollableElementRef} >

                    <div className='toolbar__wrapper'>
                        <Toolbar>
                            <NewButton
                                parentDirectoryUniqueId={routeParams.parentDirectoryUniqueId}
                                loadingService={loadingService}
                                filesUploadService={filesUploadService}
                                onRefresh={() => getFilesWithLoading(routeParams.parentDirectoryUniqueId)} />

                            <LoadingIndicator loadingService={loadingService} />

                        </Toolbar>
                    </div>

                    <div className='files-wrapper__breadcrumbs-wrapper' style={{ display: "flex" }}>
                        <Breadcrumbs>
                            {breadcrumbs.map((test: BreadcrumbItem) => {
                                return <Breadcrumb
                                    key={`breadcrumb-${test.uniqueIdentifier ?? "dashboard"}`}
                                    onClick={() => { setShowDetails(false); onBreadcrumbItemClick(test.uniqueIdentifier); }}>{test.name}</Breadcrumb>
                            })}
                        </Breadcrumbs>
                        <Input type='text'
                            placeholder='Search'
                            value={searchTerm}
                            style={{ marginLeft: "15px" }}
                            onChange={(ev, data) => {
                                // console.log("searchTerm", searchTerm);
                                setSearchTerm(data.value ?? "");
                            }}
                            onKeyDown={(ev) => { if (ev.key === "Enter") { getFilesWithLoading(routeParams.parentDirectoryUniqueId, searchTerm); } }}
                            contentAfter={
                                <Button
                                    appearance='transparent'
                                    icon={<SearchRegular />}
                                    onClick={() => { getFilesWithLoading(routeParams.parentDirectoryUniqueId, searchTerm) }}></Button>}
                        />
                    </div>


                    <FilesTable
                        isLoading={isFilesLoading}
                        loadingService={loadingService}
                        isMultiSelect
                        files={files}
                        showShareOption
                        showDeleteOption
                        sortable
                        sortBy={sortBy}
                        additionalColumns={isSearching ? [filePathColumn] : []}
                        scrollableElement={scrollableElementRef}
                        onSortChange={(data) => { setSortBy(data); }}
                        hubConnection={props.hubConnection}
                        onNavigateTo={(folder) => { setSearchTerm(""); navigate(`/files/${folder.uniqueIdentifier}`); }}
                        onSelectedItemChanged={(item) => { console.log("selected item changed", item); setSelectedItem(item) }}
                        onShowTagInputsChanged={(value) => setShowTagInputs(value)}
                        onReloadFilesEvent={() => getFilesWithLoading(routeParams.parentDirectoryUniqueId, searchTerm)}
                        onShowDetails={(item) => { setSelectedItem(item); setShowDetails(true); }}

                    />

                    {(!isFilesLoading && !files?.length) && <div className='files-empty-directory'>
                        <img className='files-empty-directory__image' src={EmptyFolderIcon} />
                        <span className='files-empty-directory__text--main'>Drag files here</span>
                        <span className='files-empty-directory__text--sub'>or use "New" button</span>
                    </div>}
                </div>
                {/* Side panel */}
                {selectedItem && showDetails && <SidePanel
                    isOpened={selectedItem && showDetails}
                    selectedItem={selectedItem}
                    fileTagDefaultOptions={fileTagDefaultOptions}
                    hubConnection={props.hubConnection}
                    loadingService={loadingService}
                    onHide={() => setShowDetails(false)}
                    onReloadFilesEvent={() => getFilesWithLoading(routeParams.parentDirectoryUniqueId, searchTerm)}
                />}


            </FilesDropZone>
        </>);
}



export default FilesListPage;