
import React, { useState } from 'react';
import { HubConnection } from '@microsoft/signalr';
import { useNavigate, useParams } from 'react-router-dom';
import { FilesTable, FilesTableSort, SharingViewType } from '../../files/files-table/files-table.component';
import { FileTableColumn, FileTableItem } from '../../files/models/file-table.models';
import { SidePanel } from '../../files/side-panel/side-panel';
import { ParentPathLink } from '../../files/parent-path-link/parent-path-link.component';
import { TagOption } from '../../files/tag-picker/tag-picker.component';
import { getDashboardSharingClient, getFilesClient, getTagsClient } from '../../services/dashboard.service';
import { BreadcrumbItem, FileModel, FileSortFields, FileSystemEntityType } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { processServerError } from '../../utils/helpers/error.helper';
import { useLoading } from '../../utils/loading-indicator.component';
import TimeAgo from 'react-timeago';
import ShowLocalTime from '../../components/show-local-time/show-local-time.component';
import { MenuItem, createTableColumn } from '@fluentui/react-components';
import { CopyRegular, DocumentTextRegular } from '@fluentui/react-icons';
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from 's365-dashboard-v2-file-picker';
import { PersonDisplay } from '../../components/person-display/person-display.component';
import { CopyFileDialog } from '../../files/copy-file-dialog/copy-file-dialog.component';
import { toast } from 'react-toastify';
import SharedWithMeImage from '../../assets/SharedWithMe.png';
type SharedWithMeFilesTableProps = {
    hubConnection?: HubConnection;
    onBreadcrumbsChange: (breadcrumbs: BreadcrumbItem[]) => void;
}

type ShareWithMeRouteParams = {
    parentSourceId?: string;
}
export type SharedWithMeFilesTableType = {
    onNavigateTo: (parentDirectoryId?: string) => void;
}




const SharedWithMeFilesTable = React.forwardRef<SharedWithMeFilesTableType, SharedWithMeFilesTableProps>((props, ref) => {

    const [files, setFiles] = React.useState<FileTableItem[]>([]);
    const [showTagInputs, setShowTagInputs] = useState<{ [key: string]: boolean }>({});
    const [selectedItem, setSelectedItem] = React.useState<FileTableItem>();
    const [showDetails, setShowDetails] = useState<boolean>(false);
    const [isLoading, loadingService] = useLoading();
    const [commentValidationState, setCommentValidationState] = React.useState<'error' | 'warning' | 'success'>();
    const [commentValidationMessage, setCommentValidationMessage] = React.useState<string>();
    const [showCopyFileModal, setShowCopyFileModal] = React.useState<boolean>(false);
    const routeParams = useParams<ShareWithMeRouteParams>();
    const navigate = useNavigate();
    const [sortBy, setSortBy] = React.useState<FilesTableSort>({ field: FileSortFields.Name, isDescending: false });

    React.useEffect(() => {
        getFilesWithLoading();
    }, [routeParams.parentSourceId, sortBy]);

    React.useEffect(() => {
        if (commentValidationMessage) {
            setCommentValidationMessage(undefined);
        }
        if (commentValidationState) {
            setCommentValidationState(undefined);
        }
    }, [selectedItem]);



    const getFilesWithLoading = async () => {
        loadingService.showLoading("Loading...", (hideMessage) => {
            getFiles().finally(() => hideMessage());
        })
    }

    const getFiles = async () => {
        loadingService.showLoading("Loading files...", async (hideMessage) => {
            try {


                const client = getDashboardSharingClient();
                const resp = await client.getSharedWithMeDocuments(routeParams.parentSourceId ?? "", sortBy.field, sortBy.isDescending);

                if (resp) {
                    setFiles(resp?.files ?? []);
                    props.onBreadcrumbsChange(resp?.breadcrumbItems ?? []);
                    if (selectedItem) {
                        // selected item is tracked in different object so it needs to be updated
                        const updatedSelectedItem = resp?.files.find(x => x.id == selectedItem.id && x.type == selectedItem.type);
                        // console.log("updatedSelectedItem", updatedSelectedItem);
                        setSelectedItem({ ...updatedSelectedItem } as FileModel);
                    }

                }

            }
            catch (error) {
                processServerError(error, undefined, "An error occurred while getting files.");
            }
            finally {
                hideMessage();
            }
        });

    }

    const getContextItems = () => {
        let menuItems: JSX.Element[] = [];
        if (!selectedItem) {
            return menuItems;
        }


        menuItems.push(...[
            <MenuItem key="copy" icon={<CopyRegular />} onClick={() => setShowCopyFileModal(true)}>Clone to Dashboard</MenuItem >,
            <MenuItem key="details" icon={<DocumentTextRegular />} onClick={() => { setShowDetails(true); }}>Details</MenuItem >
        ]);


        return menuItems;
    }



    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/shared-with-me/${parentDirectoryId ?? ''}`);
    }

    const contextMenuItems = getContextItems();

    return (<div className='page-wrapper'>
        {/* paddingRight: "1px" to hide scrollbar  */}
        <div className='files-wrapper' style={{ paddingLeft: 0, paddingRight: "1px" }}>
            <FilesTable
                isLoading={isLoading}
                loadingService={loadingService}
                showLoadingRow
                files={files}
                readonly
                sharingView={SharingViewType.SharedWithMe}
                disableSubmitForAnalysis
                disableOpenFile
                hideAnalyticsColumn
                hideAnalyzedColumn
                hideTagsColumn
                hideModifiedColumn
                basePagePath='/files/shared-with-me'
                additionalColumns={[sharedByColumn, sharedColumn]}
                sortable
                sortBy={sortBy}
                onSortChange={(data) => { setSortBy(data); }}
                contextMenuItems={contextMenuItems}
                hubConnection={props.hubConnection}
                onSelectedItemChanged={(item) => { setSelectedItem(item) }}
                onShowTagInputsChanged={(value) => setShowTagInputs(value)}
                onReloadFilesEvent={() => getFilesWithLoading()}
                onShowDetails={(item) => { setSelectedItem(item); setShowDetails(true); }} />

            {(!isLoading && !files?.length) && <div className='files-empty-directory' style={{ marginTop: "15px" }}>
                <img className='files-empty-directory__image' src={SharedWithMeImage} style={{ width: "256px" }} />
                <span className='files-empty-directory__text--main'>Shared files</span>
                <span className='files-empty-directory__text--sub'>This view shows files that have been shared with you.</span>
            </div>}

        </div>

        {/* Side panel */}
        <SidePanel
            isOpened={selectedItem && showDetails}
            selectedItem={selectedItem}
            readOnly
            fileTagDefaultOptions={[]}
            hubConnection={props.hubConnection}
            loadingService={loadingService}
            onHide={() => setShowDetails(false)}
            onReloadFilesEvent={() => getFilesWithLoading()}
        />

        {selectedItem && showCopyFileModal &&
            <CopyFileDialog
                isClone
                fileToCopy={selectedItem}
                isOpened={true}
                onClose={() => { setShowCopyFileModal(false); }}
                onSuccess={() => {
                    toast.success(`Successfully copied ${selectedItem.type == FileSystemEntityType.File ? 'file' : 'folder'}.`);
                    setShowCopyFileModal(false);
                    getFilesWithLoading();
                }}
            />}

    </div>
    );
});

const sharedColumn = {
    column: createTableColumn<FileTableItem>({
        columnId: "shared",
        renderHeaderCell: () => <>Shared</>,
    }),
    cellValue(fileItem) {
        return <ShowLocalTime date={fileItem.sharedAtUtc} multiline />
    },    
    cellTextCentered: true

} as FileTableColumn;

const sharedByColumn = {
    column: createTableColumn<FileTableItem>({
        columnId: "sharedBy",
        renderHeaderCell: () => <>Shared by</>,
    }),
    cellValue(fileItem) {
        return <PersonDisplay id={`person-${fileItem.owner?.id}`}
            name={fileItem.owner?.displayName}
            userPrincipalName={fileItem.owner?.userPrincipalName} />
    }
} as FileTableColumn;

export default SharedWithMeFilesTable;