import React from "react";
import { promises } from "stream";
import { FileTypeIcon } from "../../files/file-type-icon/file-type-icon.component";
import { getFilesClient } from "../../services/dashboard.service";
import { BreadcrumbItem, FileModel, FileSystemEntityType, FileWithBreadcrumbsResponseModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";


type FileDisplayNameProps = {
    fileUniqueIdentifier: string;
    fileVersionNumber?: number;
    hidePath?: boolean;

}



export const FileDisplayName: React.FC<FileDisplayNameProps> = (props) => {

    const [file, setFile] = React.useState<FileModel>();
    const [filePath, setFilePath] = React.useState<string>();

    React.useEffect(() => {
        if (props.fileUniqueIdentifier)
            getFileInfo();
    }, [props.fileUniqueIdentifier]);

    const getFileInfo = async () => {
        try {
            const fileInfoResp = await fileProvider.getFile(props.fileUniqueIdentifier, props.fileVersionNumber);
            //  console.log("getFileInfo", fileInfoResp);
            setFile(fileInfoResp.file);
            const fullPath = getFullPath(fileInfoResp.breadcrumbItems);
            setFilePath(fullPath);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting file info.", false);
        }

    }

    const getFullPath = (breadcrumbItems: BreadcrumbItem[]) => {

        if (breadcrumbItems == null || breadcrumbItems.length == 0) {
            return "My Work";
        }

        if (breadcrumbItems.length == 1) {
            return `My Work / ${breadcrumbItems[0].name}`;
        }

        const path = "My Work " + breadcrumbItems.reduce((x, y) => x + " / " + y.name, "");

        return path;

    }
    if (!file)
        return null;

    return <div style={{ display: "flex", alignItems: "center", wordBreak: "break-all" }}>
        <FileTypeIcon filename={file.name!} isFolder={file.type == FileSystemEntityType.Directory} style={{ marginRight: "var(--spacingHorizontalSNudge)" }} />
        <div style={{ display: "flex", flexDirection: "column", lineHeight: "1.1em" }}>
            <div>
                <span style={{ fontWeight: 600 }}>{file?.name}</span>
                {props.fileVersionNumber !== undefined && <small> version {props.fileVersionNumber}</small>}
            </div>
            {!props.hidePath && <small>{filePath}</small>}
        </div>

    </div>
}

class FileNameProvider {

    private promisses = {};


    getFile(uniqueFileId: string, fileVersionNumber?: number): Promise<FileWithBreadcrumbsResponseModel> {
        if (fileVersionNumber === undefined)
            return this.getFileLatest(uniqueFileId);

        if (!this.promisses[`${uniqueFileId}-${fileVersionNumber}`]) {
            const client = getFilesClient();
            this.promisses[`${uniqueFileId}-${fileVersionNumber}`] = client.getFile(uniqueFileId, fileVersionNumber, true);
        }

        return this.promisses[`${uniqueFileId}-${fileVersionNumber}`];

    }
    getFileLatest(uniqueFileId: string): Promise<FileWithBreadcrumbsResponseModel> {
        const client = getFilesClient();
        return client.getFileLatest(uniqueFileId, true);
    }

}

var fileProvider = new FileNameProvider();
