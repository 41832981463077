import { Tab, TabList } from "@fluentui/react-components";
import React from "react";
import SharedByMeFilesTable, { SharedByMeFilesTableType } from "./shared-by-me-files-table";
import { HubConnection } from "@microsoft/signalr";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from 's365-dashboard-v2-file-picker';
import { BreadcrumbItem } from '../../swagger-clients/s365-dashboard-v2-api-clients.service';
import { useNavigate } from "react-router-dom";
import { SharedByMeFiltersTable } from "./shared-by-me-filters-table";

type SharedByMePageProps = {
    hubConnection?: HubConnection
}
const emptyBreadcrumbs = [{ name: "Shared by me" } as BreadcrumbItem];

export const SharedByMePage: React.FC<SharedByMePageProps> = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [selectedTab, setSelectedTab] = React.useState<string>("files");
    const shareFilesTableRef = React.createRef<SharedByMeFilesTableType>();
    const navigate = useNavigate();

    return <div className='page-wrapper'>
        <div className='files-wrapper'>
            <div className='files-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    {breadcrumbs.map((test: BreadcrumbItem) => {
                        return <Breadcrumb
                            key={`breadcrumb-${test.uniqueIdentifier ?? "dashboard"}`}
                            onClick={() => { shareFilesTableRef.current?.onNavigateTo(test.uniqueIdentifier); }}>{test.name}</Breadcrumb>
                    })}
                </Breadcrumbs>
            </div>


            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => {
                navigate(`/files/shared-by-me`, { replace: true });
                setSelectedTab(data.value as string);
            }}>
                <Tab key="files" value="files" >Files</Tab>
                <Tab key="filters" value="filters">Filters</Tab>
            </TabList>
            <div className="tab-content">
                {selectedTab == "files" &&
                    <SharedByMeFilesTable
                        ref={shareFilesTableRef}
                        hubConnection={props.hubConnection}
                        onBreadcrumbsChange={(items) => {
                            setBreadcrumbs([...emptyBreadcrumbs, ...items]);
                        }} />}
                {selectedTab == "filters" && <SharedByMeFiltersTable />}
            </div>
        </div>
    </div>
}
