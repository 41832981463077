import { Label, Input, TabList, Tab, Toolbar, ToolbarButton, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, ToolbarGroup, Tooltip } from "@fluentui/react-components";
import { Alert } from "@fluentui/react-components/unstable";
import { AddRegular, DataTrendingRegular, DataScatterRegular, Copy20Regular, Delete20Regular, DocumentTable20Regular, Edit20Regular, Code20Regular, CubeSyncRegular, DocumentDataRegular, ClipboardCodeRegular } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React, { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, BreadcrumbItem as Breadcrumb } from "../../components/breadcrumbs/breadcrumbs";
import { ThumbnailImage } from "../../files/thumbnail/thumbnail-image/thumbnail-image.component";
import { getFilesClient, getFiltersClient } from "../../services/dashboard.service";
import { BreadcrumbItem, FileModel, FileWithBreadcrumbsResponseModel, FilterQuery, FilterResultModel, ShareDefinitionSourceType } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { InputParametersTab } from "../edit-filter/input-parameters-tab.component";
import { OutputParametersTab } from "../edit-filter/output-parameters-tab.component";
import { emptyBreadcrumbs, FiltersRouteParams } from "../filters.component";
import { InputParametersDetails } from "./input-parameter-details.component";
import "./filter-details.styless.scss";
import { OutputParameterDetails } from "./output-parameter-details.component";
import { GenerateExcelFile } from "../generate-excel-file/generate-excel-file.component";
import { DeleteFilterModal } from "../delete-filter-modal.component";
import { LoadingIndicator, useLoading } from "../../utils/loading-indicator.component";
import { ThumbnailModal } from "../../files/thumbnail/thumbnail-modal/thumbnail-modal.component";
import { getFileExtension } from "../../files/file-type-icon/file-type-icon.helpers";
import { FilterWebFormDetails } from "./filter-web-form-details.component";
import { toast } from "react-toastify";
import { settings } from "../../App";
import { EmbedOrShareWebFormModal } from "./embed-or-share-modal.component";
import { CreatePythonScriptToolbarButton } from "./create-python-script-modal.component";

type FilterDetailsProps = {
    filterId?: number;
    filterVersionId?: number;
    fileUniqueId?: string;
    fileVersionNumber?: number;
    hubConnection?: HubConnection;
    isSharedView?: boolean;
    onCloneSharedFilterClick?: (filter: FilterResultModel, file: FileModel) => void;
};

export const FilterDetails: React.FC<FilterDetailsProps> = (props) => {

    const [isLoading, loadingService] = useLoading();
    const [breadcrumbs, setBreadcrumbs] = React.useState<BreadcrumbItem[]>(emptyBreadcrumbs);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [formSubmitted, SetFormSubmitted] = React.useState<boolean>(false);
    const [filter, setFilter] = React.useState<FilterResultModel>();
    const [selectedTab, setSelectedTab] = React.useState<string>("input-parameters");
    const [showThumbnailModal, setShowThumbnailModal] = React.useState<boolean>(false);
    const [showGenerateExcelFileModal, setShowGenerateExcelFileModal] = React.useState<boolean>(false);
    const [showDeleteFilterModal, setShowDeleteFilterModal] = React.useState<boolean>(false);
    const [showEmbedOrShareWebFormModal, setShowEmbedOrShareWebFormModal] = React.useState<boolean>(false);
    const [showCreatePythonScriptModal, setShowCreatePythonScriptModal] = React.useState<boolean>(false);
    const routeParams = useParams<FiltersRouteParams>();
    const [copyUniqueIDToolTip, setCopyUniqueIDToolTip] = React.useState<string>();
    const navigate = useNavigate();

    const getFilterId = () => {
        if (props.isSharedView)
            return props.filterId;
        else return routeParams.filterId;
    }
    const filterId = useMemo(() => { return getFilterId(); }, [props.filterId, routeParams.filterId]);
    useEffect(() => {
        getFile();
    }, []);

    // File needs to be loaded before loading filter
    useEffect(() => {
        if (selectedFile?.uniqueIdentifier && filterId) {
            getFilter(selectedFile.uniqueIdentifier!, Number(filterId));
        }
    }, [selectedFile?.uniqueIdentifier, filterId]);

    const getFile = async () => {
        loadingService.showLoading("Loading file data...", async (hideMessage) => {
            try {
                let fileUniqueId = routeParams.uniquefileId!;
                if (props.isSharedView) {
                    fileUniqueId = props.fileUniqueId;
                }
                const client = getFilesClient();

                let fileResp: FileWithBreadcrumbsResponseModel | undefined = undefined;
                if (!!props.isSharedView) {
                    fileResp = await client.getSharedFile(fileUniqueId, props.fileVersionNumber, ShareDefinitionSourceType.Filter, filterId.toString(), true);
                } else {
                    fileResp = await client.getFileLatest(fileUniqueId, true);
                }

                if (fileResp) {
                    setSelectedFile(fileResp.file);
                    setBreadcrumbs([...emptyBreadcrumbs, ...(fileResp.breadcrumbItems ?? [])]);
                }
                return fileResp;
            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting file information.");
                return null;
            } finally {
                hideMessage();
            }
        });
    }

    const getFilter = async (fileUniqueId: string, filterId?: number) => {
        loadingService.showLoading("Loading filter data...", async (hideMessage) => {
            try {
                if (!filterId) {
                    setFilter({
                        inputFilterParameters: [],
                        outputFilterParameters: [],
                        isDeleted: false
                    } as unknown as FilterResultModel);
                } else {
                    const client = getFiltersClient();
                    let filterResp: FilterResultModel | undefined = undefined;
                    if (!props.isSharedView) {
                        filterResp = await client.getFilterLatest(fileUniqueId, filterId);
                    } else {
                        filterResp = await client.getSharedFilter(ShareDefinitionSourceType.Filter, filterId.toString(), filterId, props.filterVersionId);
                    }

                    console.log("filterResp", filterResp);
                    setFilter(filterResp);
                }

            } catch (error) {
                processServerError(error, undefined, "An error occurred while getting filter.");
            } finally {
                hideMessage();
            }
        });
    }

    const onCopyUniqueIdClick = () => {
        const { filterId, uniquefileId } = routeParams;
        let toCopy = uniquefileId.replaceAll('-', '');
        toCopy += filterId;
        navigator.clipboard.writeText(toCopy);
        setCopyUniqueIDToolTip("Copied");
        setTimeout(() => { setCopyUniqueIDToolTip(undefined); }, 5000);

    }

    const onRecreateWebFormUrlClick = () => {
        loadingService.showLoading("Re-creating web form URL...", async (hideMessage) => {

            try {
                const { filterId } = routeParams;
                const client = getFiltersClient();
                await client.recreateWebFormId(+filterId);
                toast.success("Web form URL was re-created successfully.");

            } catch (error) {
                processServerError(error, undefined, "An error occurred while re-creating web form URL.");
            } finally {
                hideMessage();
            }

        });
    }
    const onCloneClick = () => {
        if (!props.isSharedView) {
            navigate(`/files/${selectedFile?.uniqueIdentifier}/filters/edit?cloneId=${filter?.id}`)
        } else {
            props.onCloneSharedFilterClick(filter, selectedFile);
        }
    }

    const onEmbedOrShareWebFormClick = () => {
        setShowEmbedOrShareWebFormModal(true);
    }

    const onBreadcrumbItemClick = (parentDirectoryId?: string) => {
        navigate(`/files/${parentDirectoryId ?? ""}`);
    }

    const flowsheetParentDirectoryUniqueId = breadcrumbs && breadcrumbs.length > 0 ? breadcrumbs[breadcrumbs.length - 1].uniqueIdentifier : undefined;

    return <div className="page-wrapper">
        <div className="filters-wrapper">
            {!!filter && !!filter.name && !filter.isDeleted && <div className='toolbar__wrapper'>
                <Toolbar style={{ justifyContent: "space-between" }}>
                    <ToolbarGroup>
                        {!props.isSharedView && <ToolbarButton appearance='subtle' onClick={() => navigate(`/files/${selectedFile?.uniqueIdentifier}/filters/edit/${filter?.id}`)}
                            icon={<Edit20Regular />}>Edit</ToolbarButton>}
                        {!props.isSharedView && <ToolbarButton appearance='subtle' onClick={() => setShowDeleteFilterModal(true)}
                            icon={<Delete20Regular />}>Delete</ToolbarButton>}

                        <ToolbarButton appearance='subtle' onClick={onCloneClick}
                            icon={<Copy20Regular />}>Clone</ToolbarButton>
                        {!props.isSharedView && <ToolbarButton appearance='subtle' onClick={() => setShowGenerateExcelFileModal(true)}
                            icon={<DocumentTable20Regular />}>Generate Excel file</ToolbarButton>}
                        {!props.isSharedView && <Menu positioning="below-start">
                            <MenuTrigger>
                                {(triggerProps: MenuButtonProps) => {
                                    return <MenuButton style={{ minWidth: "110px", justifyContent: "space-between" }}
                                        appearance='subtle'
                                        icon={<AddRegular />}
                                        {...triggerProps}
                                    >Create</MenuButton>
                                }}
                            </MenuTrigger>

                            <MenuPopover>
                                <MenuList>
                                    <MenuItem key="create-mss" icon={<DocumentDataRegular />}
                                        onClick={() => { navigate(`/files/${selectedFile.uniqueIdentifier}/doe/edit?filterId=${filter?.id}`); }}
                                    >Design of Experiment</MenuItem>
                                    <MenuItem key="create-mss" icon={<DataScatterRegular />}
                                        onClick={() => { navigate(`/files/${selectedFile.uniqueIdentifier}/sensitivity-studies/edit?filterId=${filter?.id}`); }}
                                    >Multivariate Sensitivity Study</MenuItem>
                                    <MenuItem key="create-optimum-test" icon={<DataTrendingRegular />}
                                        onClick={() => { navigate(`/files/${selectedFile.uniqueIdentifier}/optimum-tester/edit?filterId=${filter?.id}`); }}
                                    >Optimum Test</MenuItem>

                                </MenuList>
                            </MenuPopover>
                        </Menu>}
                        {filter && filter.enableWebForm && !props.isSharedView && <ToolbarButton appearance='subtle' onClick={onEmbedOrShareWebFormClick}
                            icon={<Code20Regular />}>Embed or Share Web Form</ToolbarButton>}
                        {filter && filter.enableWebForm && !props.isSharedView && <ToolbarButton appearance='subtle' onClick={onRecreateWebFormUrlClick}
                            icon={<CubeSyncRegular />}>Re-create Web Form URL</ToolbarButton>}

                        <LoadingIndicator loadingService={loadingService} />
                    </ToolbarGroup>

                    {!props.isSharedView && <ToolbarGroup>
                        <ToolbarButton appearance='subtle' onClick={() => { setShowCreatePythonScriptModal(true); }}
                            icon={<ClipboardCodeRegular />}>Create Python script</ToolbarButton>
                        <Tooltip relationship="label" withArrow visible={!!copyUniqueIDToolTip} content={copyUniqueIDToolTip}>
                            <ToolbarButton appearance='subtle' onClick={() => { onCopyUniqueIdClick(); }}
                                icon={<Copy20Regular />}>Copy Unique ID</ToolbarButton>
                        </Tooltip>
                    </ToolbarGroup>}


                </Toolbar>

            </div>}



            {!props.isSharedView && <div className='filters-wrapper__breadcrumbs-wrapper'>
                <Breadcrumbs>
                    {breadcrumbs.map((item: BreadcrumbItem) => {
                        return <Breadcrumb
                            key={`breadcrumb-${item.uniqueIdentifier ?? "dashboard"}`}
                            onClick={() => { onBreadcrumbItemClick(item.uniqueIdentifier); }}>{item.name}</Breadcrumb>
                    })}
                    {selectedFile &&
                        <Breadcrumb
                            key={`breadcrumb-${selectedFile.uniqueIdentifier}`}
                            onClick={() => navigate(`/files/${selectedFile!.uniqueIdentifier!}/filters`)}
                        >{selectedFile.name}</Breadcrumb>}
                    <Breadcrumb key={`breadcrumb-filters`} active={true}>Filters</Breadcrumb>
                </Breadcrumbs>
            </div>}

            {
                filter && filter.isDeleted && <Alert intent="warning" style={{ marginBottom: "20px" }}>This filter is deleted.</Alert>
            }
            {
                !props.isSharedView && filter && selectedFile && !filter.isDeleted && filter.fileVersionNumber !== selectedFile.currentVersionNumber &&
                <Alert intent="warning" style={{ marginBottom: "20px" }}>This filter is created for older version of a flowsheet.
                    Update it to latest version to avoid issues with experiments.</Alert>
            }

            <div className="filter-name--group" style={{ marginTop: !!props.isSharedView ? "20px" : undefined }}>
                <Label htmlFor="input36" className="group-label">
                    Filter name:
                </Label>
                <span className="filter-name--value">{filter?.name}</span>

            </div>
            {selectedFile && props.hubConnection &&
                <div className="thumbnail--wrapper">
                    <Label htmlFor="input36" className="group-label">
                        Thumbnail:
                    </Label>
                    <div style={{ maxWidth: "250px" }}>
                        <ThumbnailImage
                            fileUniqueIdentifier={selectedFile.uniqueIdentifier!}
                            fileId={selectedFile.id!}
                            fileVersionNumber={selectedFile.currentVersionNumber!}
                            fileExtension={getFileExtension(selectedFile.name)}
                            width={200}
                            hideGenerateThumbnail
                            onClick={() => setShowThumbnailModal(true)}
                            hubConnection={props.hubConnection} />
                    </div>
                </div>}

            <TabList selectedValue={selectedTab} onTabSelect={(ev, data) => setSelectedTab(data.value as string)}>
                <Tab key="input-tab" value="input-parameters" >Input parameters</Tab>
                <Tab key="output-tab" value="output-parameters">Output parameters</Tab>
                {filter && filter.enableWebForm && <Tab key="web-form-tab" value="web-form">Web Form</Tab>}
            </TabList>
            <div className="tab-content">
                {selectedTab == "input-parameters" && selectedFile && <InputParametersDetails isLoading={isLoading} filter={filter} selectedFile={selectedFile} />

                }
                {selectedTab == "output-parameters" && selectedFile &&
                    <OutputParameterDetails isLoading={isLoading} filter={filter} selectedFile={selectedFile} />}

                {selectedTab == "web-form" && selectedFile && filter && filter.enableWebForm &&
                    <FilterWebFormDetails isLoading={isLoading} filter={filter} selectedFile={selectedFile} />}
            </div>
            <GenerateExcelFile
                isOpened={showGenerateExcelFileModal}
                selectedFile={selectedFile!}
                selectedFilter={filter!}
                parentDirectoryUniqueId={flowsheetParentDirectoryUniqueId}
                onOpenedChange={(isOpened) => { setShowGenerateExcelFileModal(isOpened) }} />

            <DeleteFilterModal
                filterId={filter?.id}
                filterName={filter?.name}
                isOpened={showDeleteFilterModal}
                onSuccess={() => { setShowDeleteFilterModal(false); navigate(`/files/${selectedFile!.uniqueIdentifier!}/filters`); }}
                onClose={() => { setShowDeleteFilterModal(false); }} />

            {selectedFile && showThumbnailModal && <ThumbnailModal
                file={selectedFile}
                hubConnection={props.hubConnection!}
                isOpened={showThumbnailModal}
                onClose={() => setShowThumbnailModal(false)}
            />}

            {showEmbedOrShareWebFormModal && <EmbedOrShareWebFormModal isOpened={showEmbedOrShareWebFormModal} onClose={() => { setShowEmbedOrShareWebFormModal(false) }} />}
            {showCreatePythonScriptModal && filter && <CreatePythonScriptToolbarButton filter={filter} isOpened={showCreatePythonScriptModal} onClose={() => { setShowCreatePythonScriptModal(false); }} />}
        </div>
    </div>
}