import React, { useState } from "react";
import { getThumbnailsClient } from "../../../services/dashboard.service";
import { ErrorResponseModel } from "../../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import thumbnail404 from "../../../assets/images/thumbnail-404.png";
import { Link, Spinner } from "@fluentui/react-components";
import "./thumbnail-image.styless.scss";
import { HubConnection } from "@microsoft/signalr";
import { GenerateThumbnailModal } from "../generate-thumbnail-modal.component";
import { ArrowSync16Filled } from "@fluentui/react-icons";
import { flowsheetExtensions, generateThumbnailExtensions } from "../../../global.variables";

type ThumbnailImageProps = {
    fileId: number,
    fileUniqueIdentifier: string,
    fileVersionNumber: number,
    fileExtension: string;
    width?: number,
    height?: number,
    alt?: string,
    hubConnection: HubConnection;
    hidePointer?: boolean;    
    onClick?: () => void;
    asBackground?: boolean,
    fullImage?: boolean,
    hideGenerateThumbnail?: boolean;
};

export const ThumbnailImage: React.FC<ThumbnailImageProps> = (props) => {

    const [imageUrl, setImageUrl] = useState<string>();
    const [isLoading, setIsLoading] = React.useState<boolean>(true);
    const [generationInProgress, setGenerationInProggress] = useState<boolean>(false);
    const [showGenerateThumbnailModal, setShowGenerateThumbnailModal] = useState<boolean>(false);

    React.useEffect(() => {
        // console.log("ThumbnailImage", props.hubConnection);
        subscribeToHubEvent();

    }, []);

    const subscribeToHubEvent = () => {
        if (props.hubConnection) {
            props.hubConnection.off('OnThumbnailGenerated');
            props.hubConnection.on('OnThumbnailGenerated', OnThumbnailGenerated);
        }
    }
    const OnThumbnailGenerated = (fileId: number, fileVersionNumber: number) => {
        //  console.log("HUB method OnThumbnailGenerated  invoked!", fileId, fileVersionNumber, props.fileId, props.fileVersionNumber);
        if (fileId == props.fileId && fileVersionNumber == props.fileVersionNumber) {
            getThumbnailUrl();
        }
    }

    React.useEffect(() => {
        //  console.log("ThumbnailImage fileId,fileVersionNumber changed", props.fileId, props.fileVersionNumber);
        subscribeToHubEvent();
        getThumbnailUrl();
    }, [props.fileId, props.fileVersionNumber]);

    const getThumbnailUrl = async () => {
        if (props.fullImage) {
            await getFullThumbnailUrl();
        } else {
            await getSmallThumbnailUrl();
        }
    }

    const getSmallThumbnailUrl = async () => {
        try {
            setIsLoading(true);
            const client = getThumbnailsClient();
            const response = await client.getThumbnailSmall(props.fileUniqueIdentifier, props.fileVersionNumber);
            if (response) {
                var reader = new FileReader();
                reader.readAsDataURL(response.data);
                reader.onloadend = function () {
                    var base64String = reader.result;
                    // console.log("thumbnail image", base64String);
                    setImageUrl(base64String?.toString());
                    setGenerationInProggress(false);
                    setIsLoading(false);
                }
                reader.onerror = () => {
                    console.log("error while reading thumbnail image");
                    setImageUrl(undefined);
                    setIsLoading(false);
                };
            } else {
                setImageUrl(undefined);
            }

        } catch (error) {
            setImageUrl(undefined);

            console.log("getThumbnailUrl error", error);
            if ((error as any).status == 418) {
                console.log("Thumbnail generation in progress.");
                setGenerationInProggress(true);
            } else {
                processServerError(error, undefined, "An error occurred while getting thumbnail image url.", false);
                setGenerationInProggress(false);
            }
        }
        finally {
            setIsLoading(false);
        }

    }

    const getFullThumbnailUrl = async () => {
        try {
            setIsLoading(true);
            const client = getThumbnailsClient();
            const response = await client.getThumbnailFull(props.fileUniqueIdentifier, props.fileVersionNumber);
            setImageUrl(response);
        }
        catch (error) {
            setImageUrl(undefined);

            console.log("getThumbnailUrl error", error);
            if ((error as any).status == 418) {
                console.log("Thumbnail generation in progress.");
                setGenerationInProggress(true);
            } else {
                processServerError(error, undefined, "An error occurred while getting thumbnail image url.", false);
                setGenerationInProggress(false);
            }
        }
        finally {
            setIsLoading(false);
        }
    }

    const isSupported = !!props.fileExtension && flowsheetExtensions.indexOf(props.fileExtension) > -1 && generateThumbnailExtensions.indexOf(props.fileExtension) > -1;


    return <>
        {/* Loading spinner */}
        {(isLoading || generationInProgress) && <div className="thumbnail-spinner-wrapper"><Spinner size="huge" /></div>}

        {/* Display image */}
        {!isLoading && !generationInProgress && imageUrl &&
            <div
                style={{
                    backgroundColor: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    ...(props.asBackground && {
                        width: "100%",
                        height: '100%',
                        backgroundImage: `url(${imageUrl})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        cursor: 'pointer'
                    })
                }}
                onClick={() => props?.onClick()}
            >
                {!props.asBackground && <img src={imageUrl} alt={props.alt}
                    onClick={() => { if (props.onClick) { props.onClick(); } }}
                    className="thumbnail-image disable-visual-search" style={{ cursor: !!props.hidePointer ? "default" : "pointer" }} />}
            </div>}

        {/* Image not found or not supported */}
        {!isLoading && !generationInProgress && !imageUrl &&
            [
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                    {isSupported && <>
                        <img
                            key={`thumbnail-img-${props.fileId}-${props.fileVersionNumber}`}
                            className="thumbnail-image-404"

                            src={thumbnail404}
                            alt={props.alt}
                            style={{ cursor: !!props.hidePointer ? "default" : "pointer" }}
                            onClick={() => {
                                if (props.onClick) { props.onClick(); }
                            }} />
                        {!props.hideGenerateThumbnail && <Link onClick={() => { setShowGenerateThumbnailModal(true); }}>Regenerate thumbnail</Link>}
                    </>}
                    {!isSupported && !isLoading &&
                        <div style={{
                            width: "100%", aspectRatio: "4/3", display: "flex",
                            alignItems: "center", textAlign: "center",
                            border: "1px solid var(--colorNeutralForeground3)",
                            backgroundColor: "var(--colorNeutralBackground3)",
                            cursor: props.onClick ? "pointer" : "auto"

                        }}
                            onClick={() => {
                                // 
                                if (props.onClick) { props.onClick(); }
                            }}
                        >
                            Thumbnail is not supported for your simulator</div>}
                </div>,

                <GenerateThumbnailModal
                    key={`generate-thumbnail-modal-${props.fileId}-${props.fileVersionNumber}`}
                    fileId={props.fileId}
                    fileVersionNumber={props.fileVersionNumber}
                    isOpened={showGenerateThumbnailModal}
                    onSuccess={() => { getThumbnailUrl(); setShowGenerateThumbnailModal(false); }}
                    onClose={() => setShowGenerateThumbnailModal(false)} />
            ]
        }
    </>
}