import React, { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { AllUserExperiments } from "./ai-for-pfd/all-user-experiments/all-user-experiments.component";
import { ExperimentDetails } from "./ai-for-pfd/details-experiment/experiment-details.component";
import { EditExperiment } from "./ai-for-pfd/edit-experiment/edit-experiment.component";
import { FlowsheetExperiments } from "./ai-for-pfd/flowsheet-experiments.component";
import { generalSettings, settings } from "./App";
import { AgentsList } from "./cluster/agents/agents-list.component";
import { DispatcherActivities } from "./cluster/dispatcher-activities/dispatcher-activities.component";
import { JobsTable } from "./cluster/jobs/jobs-table.component";
import { ClusterSettingsDetails } from "./cluster/settings/cluster-settings-details.component";
import { EditClusterSettings } from "./cluster/settings/edit-cluster-settings.component";
import { HubConnectionComponent, HubConnectionComponentType } from "./components/hub-connection/hub-connection.component";
import { LoginTracker } from "./components/login-tracker/login-tracker.component";
import { GroupsTable } from "./contacts/groups/groups-table.component";
import { ContactList } from "./contacts/my-contacts/contacts-list.component";
import { AllUserScenarios } from "./excel-runner/all-user-scenarios/all-user-scenarios.component";
import { EditDoeExperiment } from "./excel-runner/edit-experiment/edit-experiment.component";
import { EditScenario } from "./excel-runner/edit-scenario/edit-scenario.component";
import { ScenarioDetails } from "./excel-runner/scenario-details/scenario-details.component";
import { ScenariosTable } from "./excel-runner/scenarios-table.component";
import { AllUserWebForms } from "./filters/all-user-web-forms/all-user-web-forms.component";
import { EditFilter } from "./filters/edit-filter/edit-filter.component";
import { FilterDetails } from "./filters/filter-details/filter-details.component";
import { Filters } from "./filters/filters.component";
import { LoginRegisterOverlay } from "./pages/authorization/login-register-overlay";
import Layout from "./layouts/layout";
import { Eula } from "./pages/eula/eula.component";
import ExampleFilesPage from "./pages/ExampleFiles/example-files.page";
import FilesListPage from "./pages/FilesList/files-list.page";
import { FilterWebFormPage } from "./pages/filter-web-form/filter-web-form.page";
import RemovedFilesPage from "./pages/RemovedFiles/removed-files.page";
import StarredFilesPage from "./pages/StarredFiles/starred-files.page";
import { WelcomePage } from "./pages/welcome/welcome.page";
import { AllUserStudies } from "./sensitivity-studies/all-user-studies/all-user-studies.component";
import { EditSensitivityStudy } from "./sensitivity-studies/edit-sensitivity-study/edit-sensitivity-study.component";
import { SensitivityStudiesList } from "./sensitivity-studies/sensitivity-studies-list.component";
import { SensitivityStudyDetails } from "./sensitivity-studies/sensitivity-study-details/sensitivity-study-details.component";
import { EditExam } from "./take-home-exams/edit-exam/edit-exam.component";
import { ExamDetails } from "./take-home-exams/exam-details/exam-details.component";
import { Exams } from "./take-home-exams/exams.component";
import { SudentExams } from "./take-home-exams/students/student-exams.component";
import { SubmitResults } from "./take-home-exams/students/submit-results/submit-results.component";
import { AllTeacherExams } from "./take-home-exams/teacher/all-teacher-exams.component";
import { HubConnection } from "@microsoft/signalr";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Theme, webLightTheme } from "@fluentui/react-components";
import { InteractionStatus } from "@azure/msal-browser";
import { SharedByMePage } from "./pages/SharedByMe/shared-by-me.page";
import { SharedWithMePage } from "./pages/SharedWithMe/shared-with-me.page";
import { AccountConfirmationRedirectToHome } from "./pages/authorization/account-confirmation";

type RoutesDefinitionsProps = {
  currentTheme: Theme,
  onChangeTheme: (theme: Theme, isDark: boolean) => void
};

export const RoutesDefinitions = ({ currentTheme, onChangeTheme }: RoutesDefinitionsProps) => {

  // Check if user is logged
  // If user is logged in, render layout
  // If user is not logged in, redirect to login page
  // Copied condition for renderLayout and redirectToLogin from following files:
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/src/components/AuthenticatedTemplate.tsx
  // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/src/components/UnauthenticatedTemplate.tsx
  const context = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const renderLayout = useMemo(
    () => isAuthenticated && context.inProgress !== InteractionStatus.Startup,
    [context?.inProgress, isAuthenticated]
  );
  const redirectToLogin = useMemo(
    () => !isAuthenticated && context.inProgress !== InteractionStatus.Startup && context.inProgress !== InteractionStatus.HandleRedirect,
    [context?.inProgress, isAuthenticated]
  );

  const [hasAcceptedEula, setHasAcceptedEula] = React.useState<boolean>(false);

  const [dashboardHubConnection, setDashboardHubConnection] = React.useState<HubConnection>();
  const [dispatcherHubConnection, setDispatcherHubConnection] = React.useState<HubConnection>();

  const dashboardServiceHubConnectionRef = React.useCallback((hubRef: HubConnectionComponentType) => {
    if (!!hubRef && hubRef?.hubConnection !== null) {
      setDashboardHubConnection(hubRef.hubConnection);
    }
  }, [setDashboardHubConnection]);
  const dispatcherServiceHubConnectionRef = React.useCallback((hubRef: HubConnectionComponentType) => {
    if (!!hubRef && hubRef?.hubConnection !== null) {
      setDispatcherHubConnection(hubRef.hubConnection);
    }
  }, [setDispatcherHubConnection]);

  return <Routes>
    {renderLayout && <Route element={<>
      {generalSettings && generalSettings.environment !== "staging" && <LoginTracker />}
      <Eula onEulaStatusChanged={(hasAccepted: boolean) => { setHasAcceptedEula(hasAccepted); }} />
      <HubConnectionComponent ref={dashboardServiceHubConnectionRef} hasRegister={true} baseUrl={`${settings?.dashboardServiceUrl}/dashboardhub`} />
      <HubConnectionComponent ref={dispatcherServiceHubConnectionRef} baseUrl={`${settings?.dispatcherServiceUrl}/uihub`} />
      <Layout onChangeTheme={onChangeTheme} currentTheme={currentTheme} eulaAccepted={hasAcceptedEula}>

      </Layout>
    </>}>
      <Route path="/files/examples/:parentSourceId" element={<ExampleFilesPage />} />
      <Route path="/files/examples" element={<ExampleFilesPage />} />
      <Route path="/files/shared-by-me/:parentSourceId" element={<SharedByMePage hubConnection={dashboardHubConnection} />} />
      <Route path="/files/shared-by-me" element={<SharedByMePage hubConnection={dashboardHubConnection} />} />
      <Route path="/files/shared-with-me/:parentSourceId" element={<SharedWithMePage hubConnection={dashboardHubConnection} />} />
      <Route path="/files/shared-with-me" element={<SharedWithMePage hubConnection={dashboardHubConnection} />} />
      <Route path="/bin" element={<RemovedFilesPage hubConnection={dashboardHubConnection} />} />
      <Route path="/groups" element={<GroupsTable />} />
      <Route path="/exams/:examId/submit-answers" element={<SubmitResults />} />
      <Route path="/teacher-exams" element={<AllTeacherExams />} />
      <Route path="/student-exams" element={<SudentExams />} />
      <Route path="/files/starred" element={<StarredFilesPage hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/copilot/details/:experimentId" element={<ExperimentDetails />} />
      <Route path="/files/:uniquefileId/copilot/edit/:experimentId" element={<EditExperiment />} />
      <Route path="/files/:uniquefileId/copilot/edit" element={<EditExperiment />} />
      <Route path="/files/:uniquefileId/copilot" element={<FlowsheetExperiments />} />
      <Route path="/copilot" element={<AllUserExperiments hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/doe/details/:scenarioId" element={<ScenarioDetails hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/doe-xls/details/:scenarioId" element={<ScenarioDetails hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/doe-xls/edit" element={<EditScenario hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/doe/edit/:experimentId" element={<EditDoeExperiment hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/doe/edit" element={<EditDoeExperiment hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/doe-xls" element={<ScenariosTable isExcelBased={true} />} />
      <Route path="/files/:uniquefileId/doe" element={<ScenariosTable />} />
      <Route path="/doe" element={<AllUserScenarios hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/exams/details/:examId" element={<ExamDetails />} />
      <Route path="/files/:uniquefileId/exams/edit/:examId" element={<EditExam />} />
      <Route path="/files/:uniquefileId/exams/edit" element={<EditExam />} />
      <Route path="/files/:uniquefileId/exams" element={<Exams />} />
      <Route path="/files/:uniquefileId/optimum-tester/details/:studyId" element={<SensitivityStudyDetails hubConnection={dashboardHubConnection} isOptimumTester />} />
      <Route path="/files/:uniquefileId/optimum-tester/edit/:studyId" element={<EditSensitivityStudy hubConnection={dashboardHubConnection} isOptimumTester />} />
      <Route path="/files/:uniquefileId/optimum-tester/edit" element={<EditSensitivityStudy hubConnection={dashboardHubConnection} isOptimumTester />} />
      <Route path="/files/:uniquefileId/optimum-tester" element={<SensitivityStudiesList isOptimumTester />} />
      <Route path="/optimum-tester" element={<AllUserStudies isOptimumTester={true} hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/sensitivity-studies/details/:studyId" element={<SensitivityStudyDetails hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/sensitivity-studies/edit/:studyId" element={<EditSensitivityStudy hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/sensitivity-studies/edit" element={<EditSensitivityStudy hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/sensitivity-studies" element={<SensitivityStudiesList />} />
      <Route path="/sensitivity-studies" element={<AllUserStudies isOptimumTester={false} hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/filters/:filterId/details" element={<FilterDetails hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/filters/edit/:filterId" element={<EditFilter hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/filters/edit" element={<EditFilter hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:uniquefileId/filters" element={<Filters />} />
      <Route path="/web-forms" element={<AllUserWebForms hubConnection={dashboardHubConnection} />} />
      <Route path="/files/:parentDirectoryUniqueId" element={<FilesListPage hubConnection={dashboardHubConnection} />} />
      <Route path="/files" element={<FilesListPage hubConnection={dashboardHubConnection} />} />
      <Route path="/cluster/agents" element={<AgentsList />} />
      {/* <Route path="/cluster/applications" element={<ApplicationList />} /> */}
      <Route path="/cluster/jobs" element={<JobsTable />} />
      <Route path="/cluster/settings/edit" element={<EditClusterSettings />} />
      <Route path="/cluster/settings" element={<ClusterSettingsDetails />} />
      <Route path="/cluster" element={<DispatcherActivities hubConnection={dispatcherHubConnection} />} />
      <Route path="/contacts/my-contacts" element={<ContactList />} />
      <Route path="/account-confirmed" element={<AccountConfirmationRedirectToHome />} />
      <Route path="/" element={<WelcomePage hubConnection={dashboardHubConnection} />} />
    </Route>}

    {/* Public */}
    <Route path="/wf/:formId" element={<FilterWebFormPage />} />
    <Route path="/wf" element={<FilterWebFormPage />} />

    {/* Unauthenticated */}
    {redirectToLogin &&


      <Route element={<Layout onChangeTheme={() => { }} currentTheme={webLightTheme} eulaAccepted={true}>

      </Layout>}>
        <Route path="*" element={<LoginRegisterOverlay hubConnection={dashboardHubConnection} />} />
      </Route>
    }

  </Routes>;
}