import { Avatar, Button, Menu, MenuButton, MenuButtonProps, MenuItem, MenuList, MenuPopover, MenuTrigger, Theme, webDarkTheme, webLightTheme } from '@fluentui/react-components';
import React, { useEffect, useState } from 'react';
import Files from '../pages/FilesList/files-list.page';
import './layout.scss';
import {
    Desktop20Regular, PersonSupport20Regular, Cart20Regular, Add20Regular, ArrowUpload20Regular, SignOut24Regular, WeatherSunny24Regular, WeatherMoon24Regular, PanelLeftContract24Regular, PanelLeftExpand24Regular,
    FolderRegular, DataBarVerticalRegular, BookQuestionMarkRtlRegular, DesktopFlowRegular, SettingsRegular, DeleteRegular, FolderFilled, CircleRegular, CircleFilled, QuestionCircleRegular, QuestionCircle20Regular, SignOutRegular, Airplane20Regular, PlayCircleRegular, PlayCircle20Regular,
    PersonSyncRegular,
    PersonSubtractRegular,
    PersonSubtract20Regular
} from '@fluentui/react-icons';
import { AuthenticatedTemplate, useMsal } from '@azure/msal-react';
import { FileTypeIcon } from '../files/file-type-icon/file-type-icon.component';
import logo from '../assets/dashboard-logo.png';
import siderbarLogo from '../assets/sidebar-logo.png';
import { SidebarMenu } from './sidebar-menu/sidebar-menu';
import { generalSettings } from '../App';
import { IFrameModal } from '../components/iframe-modal/iframe-modal.components';
import { processServerError } from '../utils/helpers/error.helper';
import { getImpersonateUserClient, getTourClient, getUsersClient } from '../services/dashboard.service';
import { UserResponseModel } from '../swagger-clients/s365-dashboard-v2-api-clients.service';
import { TourGuideModal } from '../tour-guide/tour-guide.modal';
import { DwsimProLicenseCheckerModal } from '../components/dwsim-pro-license-checker-modal/dwsim-pro-license-checker-modal.component';
import DwsimProIcon from "../assets/images/DWSIM-Pro-Icon.png";
import { toast } from 'react-toastify';
import { ContactFormModal } from '../components/contact-form-modal/contact-form-modal.component';
import { ImpersonateUserModal } from '../auth/impersonate-user/impersonate-user-modal.component';
import ImpersonateUserService, { ImpersonateUserData } from '../services/impersonate-user.service';
import { Link, Outlet } from 'react-router-dom';


type LayoutProps = {
    children?: JSX.Element | JSX.Element[],
    eulaAccepted?: boolean,
    currentTheme: Theme,
    onChangeTheme: (theme: Theme, isDark: boolean) => void
}

export const Layout: React.FC<LayoutProps> = (props) => {
    const { instance } = useMsal();
    const [sidebarShrinked, setSidebarShrinked] = useState(!!parseInt(window.localStorage.getItem('sidebar-shrinked')));

    const [showSupportModal, setShowSupportModal] = React.useState<boolean>(false);
    const [showTourGuideModal, setShowTourGuideModal] = React.useState<boolean>(false);
    const [showImpersonateUserModal, setShowImpersonateUserModal] = React.useState<boolean>(false);
    const [skipTourIntro, setSkipTourIntro] = React.useState<boolean>(false);
    const [canImpersonateUser, setCanImpersonateUser] = React.useState<boolean>(false);
    const [isImpersonatingUser, setIsImpersonatingUser] = React.useState<boolean>(false);
    const [impersonatedUser, setImpersonatedUser] = React.useState<ImpersonateUserData>();

    const [user, setUser] = React.useState<UserResponseModel>();
    const [showDwsimProUserLicenseCheckerModal, setShowDwsimPropUserLicenseCheckerModal] = React.useState<boolean>(false);

    const account = instance.getActiveAccount();

    React.useEffect(() => {
        getUser();
        setIsImpersonatingUser(ImpersonateUserService.IsImpersonatingUser());
        setImpersonatedUser(ImpersonateUserService.UserData);
        checkIfUserCanImpersonateOtherUsers();
    }, []);

    React.useEffect(() => {
        if (props.eulaAccepted) {
            //  getTourStatus();
        }

    }, [props.eulaAccepted]);

    const getUser = async () => {
        try {
            if (!account) {
                return;
            }
            const client = getUsersClient();
            const userResp = await client.getUser(account.localAccountId);
            setUser(userResp);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting user info.");
        }
    }
    const checkIfUserCanImpersonateOtherUsers = async () => {
        try {
            const client = getImpersonateUserClient();
            const canImpersonateResp = await client.canImpersonateUser();
            setCanImpersonateUser(!!canImpersonateResp?.canImpersonateUser);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while checking if user can impersonate other users.", false);
        }

    }


    const getTourStatus = async () => {
        try {
            const client = getTourClient();
            const tourResp = await client.getTourStatus();
            const seenTour = tourResp?.hasSeenTour ?? false;
            if (!seenTour) {
                setShowTourGuideModal(true);
            }
        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting user tour status.");
        }
    }

    const handleLogout = () => {
        instance.logoutRedirect();

    };

    const toggleSidebar = () => {
        let newValue = !sidebarShrinked;
        window.localStorage.setItem('sidebar-shrinked', newValue ? '1' : '0');
        setSidebarShrinked(newValue);
    };

    return (
        <div className='wrapper'>
            <div className='top-bar'>
                <div className='top-bar__left'>
                    <Link to="/" className='top-bar__logo-wrapper'><img src={siderbarLogo} alt='Simulate 365 Dashboard' className='top-bar__logo' /></Link>
                    {generalSettings && generalSettings.environment.toLowerCase() !== "production" &&
                        <span style={{
                            textTransform: "uppercase", letterSpacing: "5px", fontWeight: "bold",
                            color: "red", fontSize: "18px"
                        }}>{generalSettings.environment}</span>
                    }
                </div>

                <div className='top-bar__right'>


                    {isImpersonatingUser &&
                        <div style={{ display: "flex" }}>
                            <div style={{ display: "flex", flexDirection: "column", pointerEvents: "none", padding: "3px" }}>
                                <span><b>Impersonating user</b></span>
                                <span>{impersonatedUser?.displayName} ({impersonatedUser?.userPrincipalName})</span>
                            </div>
                            <div>

                            </div>
                            <div className='hover-background' style={{ display: "flex" }}>
                                <Button appearance='transparent'
                                    className='hover-background'
                                    icon={<PersonSubtract20Regular />}
                                    onClick={() => { ImpersonateUserService.stopImpersonating(); }} ></Button>

                            </div>
                        </div>
                    }
                    <ul className='top-bar__menu' style={{ paddingInlineStart: "0px" }}>

                        {!isImpersonatingUser && canImpersonateUser && <li className='top-bar__menu-item-wrapper'>
                            <a href='#' onClick={(ev) => { ev.preventDefault(); setShowImpersonateUserModal(true); }} target='_blank' className='top-bar__menu-item'>
                                <PersonSyncRegular style={{ marginRight: 'var(--spacingVerticalXS)' }} />Impersonate user
                            </a>
                        </li>}


                        {/* <li className='top-bar__menu-item-wrapper'> <Button appearance='transparent' className='top-bar__menu-item' style={{ fontWeight: 400 }} onClick={() => {
                            setSkipTourIntro(true);
                            setShowTourGuideModal(true)
                        }}>
                            <PlayCircle20Regular style={{ marginRight: 'var(--spacingVerticalXS)' }} /> Virtual Tour
                        </Button>
                        </li> */}
                    </ul>
                    <ul id="headbar_list" className='top-bar__menu' style={{ paddingInlineStart: "0px" }}>


                        <li className='top-bar__menu-item-wrapper'> <a href='#' onClick={(ev) => { ev.preventDefault(); setShowDwsimPropUserLicenseCheckerModal(true); }} className='top-bar__menu-item'>
                            <img src={DwsimProIcon} style={{ marginRight: 'var(--spacingVerticalXS)' }} /> DWSIM Pro
                        </a>
                        </li>
                        <li className='top-bar__menu-item-wrapper'><a href='#' onClick={(ev) => { ev.preventDefault(); setShowSupportModal(true); }} target='_blank' className='top-bar__menu-item'>
                            <PersonSupport20Regular style={{ marginRight: 'var(--spacingVerticalXS)' }} /> Support
                        </a>
                        </li>
                        <li className='top-bar__menu-item-wrapper'> <a href='https://simulate365.com/?option=oauthredirect&app_name=simulate365aad' target='_blank' className='top-bar__menu-item'>
                            <Cart20Regular style={{ marginRight: 'var(--spacingVerticalXS)' }} /> Shop
                        </a>
                        </li>

                    </ul>

                    <AuthenticatedTemplate>
                        <ul className='top-bar__menu' style={{ paddingInlineStart: "0px" }}>
                            <li className='top-bar__menu-item-wrapper'>
                                <Button appearance='transparent'
                                    icon={props.currentTheme == webDarkTheme ?
                                        <WeatherSunny24Regular style={{ color: "var(--colorNeutralForeground1)" }} />
                                        : <WeatherMoon24Regular style={{ color: "var(--colorNeutralForeground1)" }} />}
                                    onClick={() => {
                                        if (props.currentTheme == webLightTheme) {
                                            props.onChangeTheme(webDarkTheme, true);
                                        } else {
                                            props.onChangeTheme(webLightTheme, false);
                                        }

                                    }} />
                            </li>
                            {user && <li className='top-bar__menu-item-wrapper top-bar__menu-item-wrapper--user-menu'>
                                <Menu positioning="below-end">
                                    <MenuTrigger>
                                        {(triggerProps: MenuButtonProps) =>
                                            <MenuButton
                                                appearance='transparent'
                                                style={{ paddingLeft: 0, paddingRight: 0, minWidth: "40px", color: 'var(--colorNeutralForeground1)' }}
                                                {...triggerProps}> <Avatar name={account?.name} color="blue" /></MenuButton>}
                                    </MenuTrigger>

                                    <MenuPopover>
                                        <MenuList>
                                            <MenuItem disabled className='menu-item-readonly'>
                                                <div style={{ display: "flex", flexDirection: "column" }}> <span style={{ fontWeight: "bold" }}> {user?.displayName}</span>
                                                    <span style={{ fontSize: "10px", lineHeight: "10px" }}>{user?.userPrincipalName}</span></div>
                                            </MenuItem>
                                            <MenuItem onClick={() => window.open("https://simulate365.com/my-account/", "_blank")}>
                                                <Button appearance='transparent' style={{ padding: 0 }} icon={<img src="/favicon-16x16.png" />}> My Account</Button> </MenuItem>

                                            <MenuItem onClick={() => window.open("https://support.capital-gain.eu/", "_blank")}>
                                                <Button appearance='transparent' style={{ padding: 0 }} icon={<PersonSupport20Regular />}> Support Portal</Button> </MenuItem>
                                            <MenuItem>
                                                <Button appearance='transparent' style={{ padding: 0, justifyContent: "start" }} icon={<SignOutRegular />} onClick={handleLogout}>Logout</Button></MenuItem>
                                        </MenuList>
                                    </MenuPopover>
                                </Menu>
                            </li>}
                        </ul>
                    </AuthenticatedTemplate>




                </div>
            </div>
            <div className='body'>
                <div className={'sidebar ' + (sidebarShrinked ? 'sidebar--schrinked' : '')}>
                    {sidebarShrinked ?
                        <PanelLeftExpand24Regular className='sidebar__expand-button' onClick={() => toggleSidebar()} />
                        : <PanelLeftContract24Regular className='sidebar__expand-button' onClick={() => toggleSidebar()} />
                    }
                    <SidebarMenu shrinked={sidebarShrinked} onExpand={() => setSidebarShrinked(false)} />
                </div>
                <div className='body__content'>
                    <Outlet />
                </div>
            </div>
            <div className='footer'></div>


            {showTourGuideModal && <TourGuideModal isOpened={true} skipIntro={skipTourIntro} onClose={() => { setShowTourGuideModal(false) }} />}

            {showDwsimProUserLicenseCheckerModal &&
                <DwsimProLicenseCheckerModal
                    isOpened={true}
                    onClose={() => { setShowDwsimPropUserLicenseCheckerModal(false) }} />}

            {showSupportModal && <ContactFormModal
                onHide={() => { setShowSupportModal(false); }}
                onSuccess={() => {
                    setShowSupportModal(false);
                    toast.success("Successfully sent support ticket.");
                }} />}
            {showImpersonateUserModal &&
                <ImpersonateUserModal
                    isOpened={showImpersonateUserModal}
                    onClose={() => { setShowImpersonateUserModal(false) }}
                    onSuccess={() => { setShowImpersonateUserModal(false); window.location.replace("/"); }}
                />}
        </div>
    );
}

export default Layout;