import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions, Label, RadioGroup, Radio, DialogTrigger, Field, Input, Select } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { ErrorResponse } from "@remix-run/router";
import React from "react";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { settings } from "../../App";
import { FilePicker } from "s365-dashboard-v2-file-picker";
import { LoadingButton } from "../../components/loading-button/loading-button.component";
import { getDirectoriesClient, getFilesClient, getUserToken } from "../../services/dashboard.service";
import { CopyApplicationItemModel, CopyFilePostModel, ErrorResponseModel, FileModel, FileSystemEntityType, UploadConflictAction, ValidateFileNamesPostModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { processServerError } from "../../utils/helpers/error.helper";
import { useLoading } from "../../utils/loading-indicator.component";
import { FileTableItem } from "../models/file-table.models";
import { ConflictFormModel, ConflictCopyResponse, CopyFileConflictModal } from "./copy-conflict-dialog.component";
import { getFileExtension, getFileNameWithoutExtension } from "../file-type-icon/file-type-icon.helpers";
import { SelectDoeExperimentsTable } from "./copy-objects/select-doe-experiments-table.component";
import { CopyFileObjectResponseModel, CopyFileObjectsDialog } from "./copy-objects/copy-file-objects-dialog.component";
import { excelExtensions, flowsheetExtensions } from "../../global.variables";
import CreateDirectoryModal from "../create-directory-modal/create-directory-modal.component";
import { FilePickerType } from "s365-dashboard-v2-file-picker/build/src/file-picker.component";
import { SaveFileModel } from "../../components/save-file-modal/save-file-modal.component";
import "./copy-file-dialog.styless.scss";

type CopyFileDialogProps = {
    fileToCopy: FileTableItem;
    isClone?: boolean;
    isOpened: boolean;
    onClose: () => void;
    onSuccess: () => void;
}


export const CopyFileDialog: React.FC<CopyFileDialogProps> = (props) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [accessToken, setAccessToken] = React.useState<string>();
    const [selectedFolder, setSelectedFolder] = React.useState<FileModel>();
    const [isLoading, loadingService] = useLoading();
    const [showCopyFileConflictDialog, setShowCopyFileConflictDialog] = React.useState<boolean>(false);
    const [showCreateDirectoryDialog, setShowCreateDirectoryDialog] = React.useState<boolean>(false);
    const [showCopyFileobjectsDialog, setShowCopyFileObjectsDialog] = React.useState<boolean>(false);
    const conflictResponseRef = React.useRef<ConflictCopyResponse>();
    const filePickerRef = React.useRef<FilePickerType>();

    const fileExtension = React.useMemo(() => {
        return props.fileToCopy.type == FileSystemEntityType.File ? getFileExtension(props.fileToCopy.name) : "";
    }, [props.fileToCopy]);

    const { register, handleSubmit, watch, setError, formState: { errors }, setValue } = useForm<SaveFileModel>({
        defaultValues: {
            extension: fileExtension,

            name: getFileNameWithoutExtension(props.fileToCopy.name)
        }
    });

    React.useEffect(() => { getAccessToken(); }, []);

    React.useEffect(() => { setOpen(!!props.isOpened); }, [props.isOpened]);

    const getAccessToken = async () => {

        try {
            const token = await getUserToken();
            setAccessToken(token);

        } catch (error) {
            processServerError(error, undefined, "An error occurred while getting user token.");
        }
    }
    const onModalClose = () => {
        setOpen(false);
        props.onClose();
    }

    const onCopyClick = async () => {

        try {
            conflictResponseRef.current = undefined;
            if (props.fileToCopy.type == FileSystemEntityType.Directory) {
                if (await checkDirectoryExists(selectedFolder?.uniqueIdentifier, name)) {
                    setShowCopyFileConflictDialog(true);

                } else {
                    copyFile();
                }
            } else {
                const filename = `${name}.${fileExtension}`
                if (await checkFileExists(selectedFolder?.uniqueIdentifier, filename)) {
                    setShowCopyFileConflictDialog(true);
                } else {
                    onShowCopyObjects();
                }

            }

        } catch (error) {
            const fileType = props.fileToCopy.type == FileSystemEntityType.File ? "file" : "folder";

            processServerError(error, undefined, `An error occurred while ${props.isClone ? "cloning" : "copying"} ${fileType}.`);
        }
    }
    const copyFile = async (objectsData?: CopyFileObjectResponseModel) => {
        const messageId = loadingService.showMessage(`${props.isClone ? "Cloning" : "Copying"}...`);

        try {
            const client = getFilesClient();
            let filename = conflictResponseRef.current ? conflictResponseRef.current.newName : name;
            // add file extension if document is file
            if (props.fileToCopy.type == FileSystemEntityType.File) {
                const extension = fileExtension;
                filename += `.${extension}`;
            }

            const model = new CopyFilePostModel({
                name: filename,
                sourceUniqueIdentifier: props.fileToCopy.uniqueIdentifier,
                destinationUniqueIdentifier: selectedFolder?.uniqueIdentifier,
                fileType: props.fileToCopy.type,
                conflictAction: conflictResponseRef.current?.conflictAction,
                copilotExperimentIds: objectsData?.copilotExperiments?.map(x => (new CopyApplicationItemModel(x))) ?? [],
                doeExperimentIds: objectsData?.doeExperiments?.map(x => (new CopyApplicationItemModel(x))) ?? [],
                filterIds: objectsData?.filters ?? [],
                mssStudyIds: objectsData?.mssStudies?.map(x => (new CopyApplicationItemModel(x))) ?? [],
                optimumTestIds: objectsData?.optimumTests?.map(x => (new CopyApplicationItemModel(x))) ?? [],
                theeExamIds: objectsData?.theeExams?.map(x => (new CopyApplicationItemModel(x))) ?? [],
                isClone: props.isClone
            });

            await client.copyFileOrFolder(model);
            props.onSuccess();
        } catch (error) {
            const defaultMessage = `An error occurred while ${props.isClone ? "cloning" : "copying"}
             ${props.fileToCopy.type == FileSystemEntityType.Directory ? "folder" : "file"}.`;
            processServerError(error, undefined, defaultMessage);
        } finally {
            loadingService.hideMessage(messageId);
        }

    }

    const checkDirectoryExists = async (parentUniqueId: string, directoryName: string) => {
        const messageId = loadingService.showMessage("Checking if folder exists in destination...");
        try {
            const client = getDirectoriesClient();

            const directory = await client.getDirectoryByName(parentUniqueId, directoryName);

            return !!directory;
        } catch (error) {
            const errorModel = error as ErrorResponseModel;
            if (errorModel?.errors?.length > 0 && errorModel.errors[0].indexOf("not found in parent") > -1) {
                return false;
            } else {
                throw error;
            }
        } finally {
            loadingService.hideMessage(messageId);
        }


    }
    const checkFileExists = async (parentUniqueId: string, filename: string) => {
        const messageId = loadingService.showMessage("Checking if file exists in destination...");
        try {
            const client = getFilesClient();
            const model = new ValidateFileNamesPostModel({
                parentDirectoryUniqueId: parentUniqueId,
                fileNames: [filename]

            });
            const fileExists = await client.validateFileNames(model);
            return fileExists && fileExists.existingFiles && fileExists.existingFiles.length > 0;
        } catch (error) {
            throw error;
        } finally {
            loadingService.hideMessage(messageId);
        }
    }

    const onShowCopyObjects = (data?: ConflictCopyResponse) => {

        if (!!data) {
            conflictResponseRef.current = data;
        }
 // Clone is from shared with me, we dont clone objects
        if (props.fileToCopy.type == FileSystemEntityType.File && !props.isClone) {
            const fileExtension = getFileExtension(props.fileToCopy.name);
            if (flowsheetExtensions.indexOf(fileExtension) > -1 || excelExtensions.indexOf(fileExtension) > -1) {
                setShowCopyFileObjectsDialog(true);
            } else {
                copyFile();
            }
        } else {
            copyFile();
        }

    }
    const name = watch("name");

    return <>
        <Dialog open={open} onOpenChange={(event, data) => {

            setOpen(data.open);
            if (!data.open) {
                onModalClose();
            }


        }}>
            <DialogSurface id="copy-file-modal" style={{ maxWidth: "1400px", width: "fit-content", height: "fit-content" }}>
                <form onSubmit={handleSubmit(m => onCopyClick())}>
                    <DialogBody style={{ height: "90vh", width: "90vw", maxWidth: "1200px" }}>
                        <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>
                            {props.isClone ? "Clone" : "Copy"} {props.fileToCopy.type == FileSystemEntityType.File ? "file" : "folder"}
                        </DialogTitle>
                        <DialogContent className="copy-file-modal">
                            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', overflow: "hidden" }} >
                                <div style={{ display: "flex", marginBottom: "5px", marginTop: "5px" }}>
                                    <div style={{ flexBasis: "100%" }} >
                                        <Field

                                            validationMessage={errors.name ? errors.name.message : ""}
                                            validationState={errors.name ? "error" : "none"}>
                                            <Input className="input-field--streched"
                                                placeholder={`Enter ${props.fileToCopy.type == FileSystemEntityType.File ? "file" : "folder"} name here`}
                                                {...register("name", {
                                                    required: { value: true, message: `"${props.fileToCopy.type == FileSystemEntityType.File ? "File" : "Folder"} name is required.` },
                                                    pattern: { value: /^[\w\-._ ]+$/, message: `The ${props.fileToCopy.type == FileSystemEntityType.File ? "file" : "folder"} name can only contain letters, digits, space or following characters: . - _` }
                                                })}
                                                value={name}
                                                readOnly={isLoading}
                                                onChange={(ev, data) => { setValue("name", data.value, { shouldDirty: true, shouldTouch: true }); }}
                                                contentAfter={!!fileExtension && <span>.{fileExtension}</span>} />
                                        </Field>
                                    </div>
                                    <div >
                                        <DialogActions>
                                            <LoadingButton isLoading={isLoading}
                                                style={{ marginLeft: "10px", minWidth: "100px" }}
                                                message={props.isClone ? "Cloning..." : "Copying..."}
                                                type="submit" appearance="primary" >{props.isClone ? "Clone" : "Copy"}</LoadingButton>

                                        </DialogActions>
                                    </div>

                                </div>
                                <div style={{ display: "flex", marginBottom: "5px", marginTop: "5px" }} >
                                    <div style={{ flexBasis: "100%" }}>
                                        {!!fileExtension && <Select
                                            value={fileExtension}
                                            disabled={isLoading}
                                        >

                                            return <option value={fileExtension}>File (*.{fileExtension})</option>

                                        </Select>}
                                    </div>
                                    <div>
                                        <Button type="button" style={{ marginLeft: "10px", minWidth: "100px" }} disabled={isLoading}
                                            onClick={() => setShowCreateDirectoryDialog(true)} >New Folder</Button>
                                    </div>
                                </div>

                                <div className="file-picker-wrapper">
                                    <FilePicker
                                        ref={filePickerRef}
                                        dashboardServiceUrl={settings.dashboardServiceUrl}
                                        excelRunnerServiceUrl={settings.excelRunnerServiceUrl}
                                        sensitivityStudiesServiceUrl={settings.sensitivityStudiesServiceUrl}
                                        takeHomeExamServiceUrl={settings.takeHomeExamsServiceUrl}
                                        userAccessToken={accessToken}
                                        // by adding this file type we remove all files, and leave folders
                                        filterFileTypes={["notfiletype"]}
                                        // prevent user to move directory in itself
                                        filterDirectoryUniqueIds={props.fileToCopy && props.fileToCopy.type == FileSystemEntityType.Directory ? [props.fileToCopy.uniqueIdentifier] : undefined}
                                        onSelectedFolderChanged={(folder: FileModel) => { setSelectedFolder(folder); }}
                                    />
                                </div>
                            </div>


                        </DialogContent>

                    </DialogBody>
                </form>
            </DialogSurface>
        </Dialog>
        {showCopyFileobjectsDialog &&
            <CopyFileObjectsDialog
                isClone={props.isClone}
                fileToCopy={props.fileToCopy}
                isOpened={showCopyFileobjectsDialog}
                onSuccess={(data) => {

                    setShowCopyFileObjectsDialog(false);
                    copyFile(data);
                }}
                onClose={() => { setShowCopyFileObjectsDialog(false); }}

            />}

        {showCopyFileConflictDialog &&
            <CopyFileConflictModal
                fileToCopy={props.fileToCopy}
                isClone={props.isClone}
                destinationDirectoryUniqueId={selectedFolder?.uniqueIdentifier}
                onCopy={(data) => { setShowCopyFileConflictDialog(false); onShowCopyObjects(data); }}
                onDismiss={() => { setShowCopyFileConflictDialog(false); }} />}

        {showCreateDirectoryDialog && <CreateDirectoryModal
            parentDirectoryUniqueId={selectedFolder?.uniqueIdentifier}
            onSuccess={() => { setShowCreateDirectoryDialog(false); filePickerRef.current?.getFilesAndFolders(); removeTabsterFromModal(); }}
            isOpened={true}
            onClose={() => { setShowCreateDirectoryDialog(false); removeTabsterFromModal(); }}
        />}
    </>
}

// when conflict modal opens and user closes it, input field for name can't be focused
const removeTabsterFromModal = () => {
    const dialogSurface = document.getElementById("copy-file-modal");
    if (dialogSurface) {
        dialogSurface.setAttribute("data-tabster", "");
    }
}