import React, { useRef } from "react";
import { useLoading } from "../../../utils/loading-indicator.component";
import { UpdateDataFromFileStatus, UpdateDataFromFileStatusModel } from "../../../swagger-clients/excel-runner-api-clients.service";
import { getScenariosClient } from "../../../services/excel-runner.service";
import { processServerError } from "../../../utils/helpers/error.helper";
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Spinner } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { GLOBAL_STYLES } from "../../../styles";

type ReloadDataFromFileStatusDialogProps = {
    requestId: string;
    isOpened?: boolean;
    onSuccess?: () => void;
    onClose?: () => void;
}

export const ReloadDataFromFileStatusDialog: React.FC<ReloadDataFromFileStatusDialogProps> = (props) => {

    const [open, setOpen] = React.useState(false);
    const [isLoading, loadingService] = useLoading(true);
    const [isReloadInProgress, setIsReloadInProgress] = React.useState<boolean>(false);
    const [errorMessage, setErrorMessage] = React.useState<string>();
    const statusRef = useRef<UpdateDataFromFileStatusModel>();
    const intervalRef = useRef<NodeJS.Timer>();

    React.useEffect(() => {
        setOpen(!!props.isOpened);

    }, [props.isOpened]);

    React.useEffect(() => {
        if (!isReloadInProgress && statusRef.current?.status == UpdateDataFromFileStatus.Finished) {
            props.onSuccess();
        }
    }, [isReloadInProgress, statusRef.current]);

    React.useEffect(() => {
        onInit();
        return () => clearInterval(intervalRef.current);
    }, []);

    const onModalClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }

    const onInit = async () => {

        let intervalMs = 2000;

        intervalRef.current = setInterval(async () => {
            if (statusRef.current?.status == UpdateDataFromFileStatus.Failed || statusRef.current?.status == UpdateDataFromFileStatus.Finished) {
                return clearInterval(intervalRef.current);
            }
            await checkUpdateDataFromFileStatus();
            intervalMs = 1000;
        }, intervalMs);

    }


    const checkUpdateDataFromFileStatus = async () => {

        const messageId = loadingService.showMessage("Checking for reload data status...");
        try {
            const client = getScenariosClient();
            const statusResp = await client.getReloadDataStatus(props.requestId);

            statusRef.current = statusResp;
            if (!statusResp) {
                setErrorMessage("Request not found.");
            } else {
                setErrorMessage(undefined);
                if (statusResp.status == UpdateDataFromFileStatus.InProgress) {
                    setIsReloadInProgress(true);
                }
                else if (statusResp.status == UpdateDataFromFileStatus.Finished) {
                    setIsReloadInProgress(false);
                }
                else if (statusResp.status == UpdateDataFromFileStatus.Failed) {
                    setIsReloadInProgress(false);
                    setErrorMessage(statusResp.errorMessage);
                }
            }


        } catch (error) {
            processServerError(error, undefined, "An error occurred while checking reloading data status.");
            clearInterval(intervalRef.current);
        } finally {
            loadingService.hideMessage(messageId);
        }

    }


    return (<Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
    }}>
        <DialogSurface>

            <DialogBody>
                <DialogTitle action={<Button appearance="transparent" onClick={onModalClose} icon={<Dismiss24Filled />} />}>Reload Data</DialogTitle>
                <DialogContent className={GLOBAL_STYLES.DIALOG_CONTENT_WITH_SPACING}>
                    <div style={{ paddingTop: "20px", paddingBottom: "20px" }}>
                        {isLoading && !statusRef.current && !errorMessage && <Spinner size="extra-small" appearance="primary" label="Checking status..." />}
                        {!!statusRef?.current && (statusRef.current.status == UpdateDataFromFileStatus.InProgress || statusRef.current.status == UpdateDataFromFileStatus.InQueue)
                            && <Spinner size="extra-small" appearance="primary" label="Data is being reloaded from excel file..." />}
                        {!!statusRef.current && statusRef.current.status == UpdateDataFromFileStatus.Failed && !!errorMessage && <span style={{ color: "red" }}>{errorMessage}</span>}
                        {!!statusRef.current && statusRef.current.status == UpdateDataFromFileStatus.Finished &&
                            <span>
                                The data has been successfully reloaded from the Excel file.
                            </span>}
                    </div>
                </DialogContent>
                <DialogActions>

                    <Button appearance="secondary" onClick={() => props.onClose()}>Close</Button>
                </DialogActions>
            </DialogBody>

        </DialogSurface>
    </Dialog>);

}