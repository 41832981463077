import { useMsal } from "@azure/msal-react";
import React from "react";
import { useNavigate } from "react-router-dom";

type AccountConfirmationRedirectToHomeProps = {

}
// this component is used to redirect user to welcome page, when he is logged in to ms account. MS returns user to confirmation url
// but now user is logged in and redirected
export const AccountConfirmationRedirectToHome: React.FC<AccountConfirmationRedirectToHomeProps> = (props) => {
    const { instance } = useMsal();
  const navigate = useNavigate();

    React.useEffect(() => {
        // Check if the user is already authenticated
        const checkAuthentication = async () => {
          const accounts = instance.getAllAccounts();
          
          if (accounts.length > 0) {
            // User is authenticated, redirect to homepage
            navigate("/");
          } else {
            // User is not authenticated, initiate login
            instance.loginRedirect();
          }
        };
    
        checkAuthentication();
      }, [instance, navigate]);

    return <div></div>

}