import { Dialog, DialogSurface, DialogBody, DialogTitle, Button, DialogContent, DialogActions } from "@fluentui/react-components";
import { Dismiss24Filled } from "@fluentui/react-icons";
import { HubConnection } from "@microsoft/signalr";
import React from "react";
import { ApplicationTryNowButton } from "../../components/application-try-now-button/application-try-now-button.component";
import { WizardApplication } from "../../components/create-experiment-wizard/create-experiment-wizard.modal";
import { FilterDetails } from "../filter-details/filter-details.component";
import { CloneSharedFilterPostModel, FileModel, FilterResultModel } from "../../swagger-clients/s365-dashboard-v2-api-clients.service";
import { SaveFileModal, SaveFileModel } from "../../components/save-file-modal/save-file-modal.component";
import { getFileExtension, getFileNameWithoutExtension } from "../../files/file-type-icon/file-type-icon.helpers";
import { getFiltersClient } from "../../services/dashboard.service";
import { toast } from "react-toastify";
import { processServerError } from "../../utils/helpers/error.helper";

type FilterDetailsModalProps = {
    filterId: number;
    filterVersionId?: number;
    fileUniqueId: string;
    fileVersionNumber: number;
    isOpened?: boolean;
    hubConnection: HubConnection;
    onClose?: () => void;
}

export const FilterDetailsModal: React.FC<FilterDetailsModalProps> = (props) => {
    const [open, setOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [selectedFile, setSelectedFile] = React.useState<FileModel>();
    const [selectedFilter, setSelectedFilter] = React.useState<FilterResultModel>();
    const [showSaveFileModal, setShowSaveFileModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        setOpen(!!props.isOpened);
    }, [props.isOpened]);

    const onClose = () => {
        setOpen(false);

        if (props.onClose) {
            props.onClose();
        }
    }

    const onCloneSharedFilterClick = (filter: FilterResultModel, file: FileModel) => {
        setSelectedFile(file);
        setSelectedFilter(filter);
        setShowSaveFileModal(true);
    }

    const onCloneFilterAndFlowsheetToDashboard = async (data: SaveFileModel) => {
        try {
            const client = getFiltersClient();
            const model = new CloneSharedFilterPostModel({
                filterId: selectedFilter.id,
                filename: `${data.name}.${data.extension}`,
                flowsheetUniqueId: selectedFile.uniqueIdentifier,
                flowsheetVersionNumber: selectedFilter.fileVersionNumber,
                destinationDirectoryUniqueId: data.parentDirectoryUniqueId,
                uploadConflictAction: data.conflictAction
            });
            await client.cloneSharedFilter(model);
            toast.success("Filter cloned successfully.");
            onClose();
        } catch (error) {
            processServerError(error, undefined, "An error occurred while cloning shared filter.");
        }


    }


    return <Dialog open={open} onOpenChange={(event, data) => {
        setOpen(data.open);
        if (!data.open) {
            onClose();
        }

    }}>
        <DialogSurface style={{ minWidth: "90vw" }}>

            <DialogBody style={{ height: "90vh" }}>
                <DialogTitle action={<Button appearance="transparent" onClick={onClose} icon={<Dismiss24Filled />} />}
                    style={{ display: "flex", alignItems: "center", gap: "20px" }}>
                    <span>Shared filter details</span>
                </DialogTitle>
                <DialogContent>
                    <FilterDetails
                        isSharedView
                        filterId={props.filterId}
                        filterVersionId={props.filterVersionId}
                        fileUniqueId={props.fileUniqueId}
                        fileVersionNumber={props.fileVersionNumber}
                        onCloneSharedFilterClick={onCloneSharedFilterClick}
                        hubConnection={props.hubConnection} />

                    {selectedFile && selectedFilter && showSaveFileModal &&
                        <SaveFileModal
                            isOpened={true}
                            modalTitle={"Save filter's flowsheet"}
                            saveButtonLabel="Save"
                            defaultName={getFileNameWithoutExtension(selectedFile.name)}
                            fileExtension={getFileExtension(selectedFile.name)}
                            fileExtensionText={`DWSIM file (*.${getFileExtension(selectedFile.name)})`}
                            onHide={() => { setShowSaveFileModal(false); setSelectedFile(undefined); setSelectedFilter(undefined); }}
                            onSuccess={onCloneFilterAndFlowsheetToDashboard}
                        />
                    }

                </DialogContent>
            </DialogBody>
        </DialogSurface>
    </Dialog>
}